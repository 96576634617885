import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  IconButton,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { domain } from "../Components/config";

const DepositModal = ({ open, onClose }) => {
  const [depositBonuses, setDepositBonuses] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchDepositBonuses = async () => {
      try {
        const response = await axios.get(`${domain}/all-deposit-bonuses`);
        setDepositBonuses(response.data);
      } catch (error) {
        console.error("Error fetching deposit bonuses:", error);
      }
    };

    fetchDepositBonuses();
  }, [open]);

  const handleClose = () => {
    onClose();
    if (isChecked) {
      const today = new Date().toDateString();
      localStorage.setItem("depositModalDismissedDate", today);
    }
  };

  const handleDeposit = () => {
    navigate("/recharge");
  };

  const handleActivity = () => {
    navigate("/activity/FirstRecharge")
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: 340,
        bgcolor: "#2B3270",
        boxShadow: 24,
        borderRadius: 4,
        overflow: "hidden",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#374992",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <IconButton
          edge="end"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Extra first deposit bonus
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            mt: 1,
            fontSize: "12px",
          }}
        >
          Each account can only receive rewards once
        </Typography>
      </Box>

      {/* Bonus Cards */}
      <Box
        sx={{
          padding: 1,
          overflowY: "auto",
          maxHeight: "60vh",
        }}
      >
        {depositBonuses.map((bonus) => (
          <Card
            key={bonus._id}
            sx={{
              mb: 2,
              padding: 0,
              backgroundColor: "#374992",
              boxShadow: "none",
              border: "1px solid #374992",
              borderRadius: 4,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "14px", fontWeight: "bold",color:"white" }}
                >
                  First deposit{" "}
                  <Typography component="span" sx={{ color: "#BA814B" }}>
                    ₹{bonus.minimumDeposit}
                  </Typography>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#BA814B",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  + ₹{bonus.bonus}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{ color: "#9198B7", mb: 1, fontSize: "12px" }}
              >
                Deposit ₹{bonus.minimumDeposit} for the first time and receive
                ₹{bonus.bonus} bonus
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={0}
                  sx={{
                    flexGrow: 1,
                    height: 8,
                    paddingY:"6px",
                    borderRadius: 4,
                    backgroundColor: "#242B61",
                    color:"white",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#242B61",
                    },
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    left: "30%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#0F6518",
                    fontWeight: "bold",
                    color:"white"
                  }}
                >
                  0/{bonus.minimumDeposit}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    ml: 2,
                    minWidth: 80,
                    height: 30,
                    fontSize: "12px",
                    borderColor: "#BA814B",
                    color: "#BA814B",
                    "&:hover": {
                      borderColor: "#BA814B",
                      backgroundColor: "rgba(15, 101, 24, 0.04)",
                    },
                  }}
                  onClick={handleDeposit}
                >
                  Deposit
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Footer */}
      <Box
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid 374992",
          bgcolor: "#374992",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            sx={{
              color: "#2AA0F3",
              "&.Mui-checked": {
                color: "#2AA0F3",
              },
            }}
          />
          <Typography variant="body2" sx={{ fontSize: "12px",color:"white" }}>
            No more reminders today
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#2AA0F3",
            color: "white",
            borderRadius: "100px",
            "&:hover": {
              bgcolor: "#2AA0F3",
            },
          }}
          onClick={handleActivity}
        >
          Activity
        </Button>
      </Box>
    </Box>
  </Modal>
  );
};

DepositModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DepositModal;