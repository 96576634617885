import { useEffect, useState } from "react"
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Container,
  styled,
  LinearProgress,
} from "@mui/material"
import { Link, useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import CasinoIcon from "@mui/icons-material/Casino"
import Mobile from "./Mobile"
import one from "../public/one.png"
import two from "../public/two.png"
import three from "../public/three.png"
import four from "../public/four.png"
import five from "../public/five.png"
import six from "../public/six.png"

// Styled components
const ScrollableBox = styled(Box)({
  display: "flex",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
})

const LotteryButton = styled(Box)(({ theme, active }) => ({
  backgroundColor: active ? "#61a9ff" : "#2B3270",
  color: active ? "white" : "#ACAFB3",
  cursor: "pointer",
  padding: "2px 14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  minWidth: "80px",
  margin: "5px",
  borderRadius: "10px",
}))

const OddsProgressBar = styled(LinearProgress)(({ value }) => ({
  height: 22,
  borderRadius: 8,
  backgroundColor: "#2B3270",
  "& .MuiLinearProgress-bar": {
    borderRadius: 8,
    backgroundColor: value > 50 ? "#61A9FF" : "#FF0000",
  },
}))

const gamesData = [
  // Original category using images from the 'original' array
  {
    id: 7,
    name: "Treasure Hunt",
    image: "/assets/800_20240601192244182.jpg",
    category: "Original",
    odds: "90.00%",
  },
  {
    id: 8,
    name: "Plinko Fun",
    image: "/assets/plinko.png",
    category: "Original",
    odds: "85.00%",
  },
  {
    id: 9,
    name: "Epic Quest",
    image: "/assets/110_20240330120322752.png",
    category: "Original",
    odds: "88.50%",
  },
  {
    id: 10,
    name: "Mega Jackpot",
    image: "/assets/22001.png",
    category: "Original",
    odds: "82.75%",
  },
  {
    id: 11,
    name: "K3 Surprise",
    image: "/assets/22004.png",
    category: "Original",
    odds: "81.00%",
  },
  {
    id: 12,
    name: "K3 Adventure",
    image: "/assets/22005.png",
    category: "Original",
    odds: "86.00%",
  },

  // Slots category using images from the 'Slots' array
  {
    id: 13,
    name: "Jackpot Mania",
    image: "/assets/Jili Game.png",
    category: "Slots",
    odds: "84.00%",
  },
  {
    id: 14,
    name: "Lucky Spin",
    image: "/assets/Pg game.png",
    category: "Slots",
    odds: "77.50%",
  },
  {
    id: 15,
    name: "Mega Win",
    image: "/assets/Ag game.png",
    category: "Slots",
    odds: "85.25%",
  },
  {
    id: 16,
    name: "Gold Rush",
    image: "/assets/U game.png",
    category: "Slots",
    odds: "80.30%",
  },
  {
    id: 17,
    name: "Coq Game",
    image: "/assets/Coq game.png",
    category: "Slots",
    odds: "89.10%",
  },
  {
    id: 18,
    name: "Evolution Game",
    image: "/assets/Evolution.png",
    category: "Slots",
    odds: "90.40%",
  },

  // Sports category using images from the 'Sports' array
  {
    id: 19,
    name: "Champion's League",
    image: "/assets/gsports.png",
    category: "Sports",
    odds: "88.77%",
  },
  {
    id: 20,
    name: "World Cup",
    image: "/assets/cmd.png",
    category: "Sports",
    odds: "84.17%",
  },
  {
    id: 21,
    name: "Olympic Games",
    image: "/assets/saba.png",
    category: "Sports",
    odds: "82.50%",
  },

  // {
  //   id: 24,
  //   name: "Basketball Blitz",
  //   image: "/assets/rabbit.png",
  //   category: "Sports",
  //   odds: "79.80%",
  // },

  // Fishing category using images from the 'Fishing' array
  {
    id: 26,
    name: "Ocean Catch",
    image: "/assets/119.png",
    category: "Fishing",
    odds: "82.50%",
  },
  {
    id: 27,
    name: "Tropical Fish",
    image: "/assets/20.png",
    category: "Fishing",
    odds: "84.75%",
  },
  {
    id: 28,
    name: "Catch of the Day",
    image: "/assets/212.png",
    category: "Fishing",
    odds: "90.00%",
  },
  {
    id: 29,
    name: "Sea Adventure",
    image: "/assets/32.png",
    category: "Fishing",
    odds: "87.60%",
  },
  {
    id: 30,
    name: "Fishing Frenzy",
    image: "/assets/42.png",
    category: "Fishing",
    odds: "85.00%",
  },

  // Adding more data to the Fishing category
  {
    id: 31,
    name: "Aqua Blast",
    image: "/assets/AB3 (1).png",
    category: "Fishing",
    odds: "83.20%",
  },
  {
    id: 32,
    name: "Tidal Spin",
    image: "/assets/AT01.png",
    category: "Fishing",
    odds: "86.70%",
  },
  {
    id: 33,
    name: "Marine Mayhem",
    image: "/assets/AT05.png",
    category: "Fishing",
    odds: "89.90%",
  },
  {
    id: 34,
    name: "Golden Ocean",
    image: "/assets/GO02.png",
    category: "Fishing",
    odds: "84.00%",
  },
  {
    id: 35,
    name: "Deep Sea Treasure",
    image: "/assets/67001.png",
    category: "Fishing",
    odds: "88.00%",
  },
  {
    id: 36,
    name: "Coral Rush",
    image: "/assets/7001.png",
    category: "Fishing",
    odds: "87.50%",
  },
  {
    id: 37,
    name: "Whale Hunter",
    image: "/assets/7002.png",
    category: "Fishing",
    odds: "89.00%",
  },
  {
    id: 38,
    name: "Shark Frenzy",
    image: "/assets/7003.png",
    category: "Fishing",
    odds: "85.50%",
  },
  {
    id: 39,
    name: "Deep Dive",
    image: "/assets/7004.png",
    category: "Fishing",
    odds: "84.20%",
  },
  {
    id: 40,
    name: "Sea Dragon",
    image: "/assets/7005.png",
    category: "Fishing",
    odds: "90.10%",
  },
  {
    id: 41,
    name: "Reef Hunter",
    image: "/assets/7006.png",
    category: "Fishing",
    odds: "83.75%",
  },
  {
    id: 42,
    name: "Underwater Riches",
    image: "/assets/7007.png",
    category: "Fishing",
    odds: "88.50%",
  },
  {
    id: 43,
    name: "Splash Jackpot",
    image: "/assets/1.png",
    category: "Fishing",
    odds: "86.00%",
  },
  {
    id: 44,
    name: "Sunken Treasures",
    image: "/assets/60.png",
    category: "Fishing",
    odds: "87.30%",
  },
  {
    id: 45,
    name: "Mermaid's Cove",
    image: "/assets/71.png",
    category: "Fishing",
    odds: "85.70%",
  },
  {
    id: 46,
    name: "Tropical Waves",
    image: "/assets/74.png",
    category: "Fishing",
    odds: "89.50%",
  },
  {
    id: 47,
    name: "Stormy Seas",
    image: "/assets/82.png",
    category: "Fishing",
    odds: "83.25%",
  },
  {
    id: 48,
    name: "Reel King",
    image: "/assets/510.png",
    category: "Fishing",
    odds: "86.40%",
  },
  {
    id: 49,
    name: "Fu Wa Fishing",
    image: "/assets/SFG_WDFuWaFishing.png",
    category: "Fishing",
    odds: "84.50%",
  },
  {
    id: 50,
    name: "Golden Blast Fishing",
    image: "/assets/SFG_WDGoldBlastFishing.png",
    category: "Fishing",
    odds: "87.10%",
  },
  {
    id: 51,
    name: "Golden Fortune Fishing",
    image: "/assets/SFG_WDGoldenFortuneFishing.png",
    category: "Fishing",
    odds: "89.30%",
  },
  {
    id: 52,
    name: "Golden Tyrant Fishing",
    image: "/assets/SFG_WDGoldenTyrantFishing.png",
    category: "Fishing",
    odds: "88.00%",
  },
  {
    id: 53,
    name: "Merry Island Fishing",
    image: "/assets/SFG_WDMerryIslandFishing.png",
    category: "Fishing",
    odds: "90.25%",
  },

  // Rummy category using images from the 'Rummy' array
  { id: 54, name: "Rummy Showdown", image: "/assets/365games.png", category: "Rummy", },
  { id: 55, name: "Rummy Showdown", image: "/assets/v8poker.png", category: "Rummy", },
  
]

const PopularItems = [
  {
    id: 1,
    name: "Mystic Treasures",
    image: "/assets/800_20240601192244182.jpg",
    category: "Popular",
    odds: "88.77%",
  },
  {
    id: 2,
    name: "Fortune's Chance",
    image: "/assets/22001.png",
    category: "Popular",
    odds: "81.83%",
  },
  {
    id: 22,
    name: "Grand Prix",
    image: "/assets/royal.png",
    category: "Sports",
    odds: "87.00%",
  },
  {
    id: 23,
    name: "Football Frenzy",
    image: "/assets/Ganesh.png",
    category: "Sports",
    odds: "90.20%",
  },
  {
    id: 4,
    name: "Lucky Rabbit",
    image: "/assets/rabbit.png",
    category: "Popular",
    odds: "91.46%",
  },
]

const CasinoItems = [
  { id: 1, imgSrc: "/assets/crazy.png", game: "Win Go" },
  { id: 2, imgSrc: "/assets/DragonTiger00001.png", game: "Win Go" },
  { id: 3, imgSrc: "/assets/AmericanTable001 (1).png", game: "Win Go" },
  { id: 4, imgSrc: "/assets/48z5pjps3ntvqc1b (1).png", game: "Win Go" },
  { id: 5, imgSrc: "/assets/AndarBahar000001 (1).png", game: "K3" },
  { id: 6, imgSrc: "/assets/SuperSicBo000001.png", game: "K3" },
]

const lotteryItems = [
  {
    id: 1,
    name: "Win Go",
    image: "/assets/lotterycategory_20240123160120h4kw.png",
    description: "Guess Number",
    description1: "Green/Red/Violet to win",
    path: "/timer/30sec",
  },
  {
    id: 2,
    name: "K3",
    image: "/assets/lotterycategory_20240123160129bev8.png",
    description: "Guess Number",
    description1: "Big/Small/Odd/Even",
    path: "/k3/1min",
  },
  {
    id: 3,
    name: "5D",
    image: "/assets/lotterycategory_20240123160137lok5.png",
    description: "Guess Number",
    description1: "Big/Small/Odd/Even",
    path: "",
  },
  {
    id: 4,
    name: "Trx Win",
    image: "/assets/lotterycategory_202401231601472sqb.png",
    description: "Guess Number",
    description1: "Green/Red/Violet to win",
    path: "/trx/1min",
  },
]

const categories = [
  { name: "Popular", image: "/assets/trophy.png" },
  { name: "Lottery", image: "/assets/bingo.png" },
  { name: "Casino", image: "/assets/girl.png" },
  { name: "Slots", image: "/assets/slot2.png" },
  { name: "Sports", image: "/assets/sports2.png" },
  { name: "Rummy", image: "/assets/rummy2.png" },
  { name: "Fishing", image: "/assets/fish2.png" },
  { name: "Original", image: "/assets/original2.png" },
]

const LotteryItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid #374992",
  borderRadius: "10px",
  width: "100%",
  backgroundColor: "#374992",
  textAlign: "left",
  marginLeft: "5px",
  marginBottom: "0px",
  // padding: "10px",
  paddingBottom: "0px",
  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
})

const LotteryDescription = styled(Box)({
  marginTop: "8px",
})

const LotteryImage = styled("img")({
  width: "100%",
  borderRadius: "8px",
  objectFit: "cover",
})

const GamesGrid = () => {
  const { id } = useParams()
  const activeCategoryId = id
  const [activeCategory, setActiveCategory] = useState(id)

  useEffect(() => {
    setActiveCategory(id)
    console.log(id, "activeCategoryId")
  }, [id])

  const filteredGames =
    activeCategory === "All"
      ? gamesData
      : gamesData.filter((game) => game.category === activeCategory)

  const filteredLotteryItems = activeCategory === "Lottery" ? lotteryItems : []

  const filteredCasinoItems = activeCategory === "Casino" ? CasinoItems : []

  const filteredPopularItems = activeCategory === "Popular" ? PopularItems : []

  return (
    <Mobile>
      <Container
        disableGutters
        maxWidth="xs"
        sx={{
          bgcolor: "#22275B",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            bgcolor: "#2B3270",
            padding: "8px 10px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <Link to="/home" style={{ textDecoration: "none", color: "inherit" }}>
            <ChevronLeftIcon sx={{ fontSize: 30 }} />
          </Link>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            {activeCategory}
          </Typography>
        </Box>

        <ScrollableBox my={1} sx={{ flexShrink: 0 }}>
          {categories.map((category) => (
            <LotteryButton
              key={category.name}
              active={activeCategory === category.name}
              onClick={() => setActiveCategory(category.name)}
            >
              <img
                src={category.image}
                alt={category.name}
                style={{ width: "50px", height: "35px", marginBottom: "1px" }}
              />
              <Typography variant="caption">{category.name}</Typography>
            </LotteryButton>
          ))}
        </ScrollableBox>

        <Grid container spacing={1} sx={{ p: 2, overflowY: "auto" }}>
          <>
            {activeCategory === "Lottery" &&
              filteredLotteryItems.length > 0 && (
                <>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      ml: 1,
                      color: "white",
                      mb: 1,
                    }}
                  >
                    <span
                      style={{
                        color: "#61a9ff",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      |
                    </span>{" "}
                    Lottery
                  </Typography>
                  <Grid container spacing={2}>
                    {filteredLotteryItems.map((item) => (
                      <Grid item xs={12} sm={11} md={11} key={item.id}>
                        <Link to={item.path} style={{ textDecoration: "none" }}>
                          <LotteryItem
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: 2,
                            }}
                          >
                            <Box
                              sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography variant="h6" sx={{ color: "white" }}>
                                {item.name}
                              </Typography>
                              <LotteryDescription>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#7C86AF" }}
                                >
                                  {item.description}
                                  <br />
                                  {item.description1}
                                </Typography>
                              </LotteryDescription>
                            </Box>
                            <LotteryImage
                              src={item.image}
                              alt={item.name}
                              sx={{
                                width: "60px",
                                height: "55px",
                                marginLeft: 2,
                              }} // Adjusted to use `sx`
                            />
                          </LotteryItem>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
          </>
          <>
            {activeCategory === "Popular" &&
              filteredPopularItems.length > 0 && (
                <>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      ml: 1,
                      color: "white",
                      mb: 1,
                    }}
                  >
                    <span
                      style={{
                        color: "#61a9ff",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      |
                    </span>{" "}
                    Popular
                  </Typography>
                  <Grid container spacing={2}>
                    {filteredPopularItems.map((item, index) => (
                      <Grid
                        item
                        xs={4}
                        key={item.id || index}
                        sx={{
                          border: "none",
                          position: "relative",
                          bgcolor: "transparent",
                          p: 1,
                        }}
                      >
                        <img
                          src={item.image}
                          alt={item.description}
                          style={{
                            width: "110px",
                            height: "110px",
                            border: "5px solid pink",
                            borderRadius: "10px",
                          }}
                        />
                        <Box
                          sx={{ p: 0.5, bgcolor: "transparent", flexGrow: 0 }}
                        >
                          <Box
                            sx={{ position: "relative", textAlign: "center" }}
                          >
                            {item.odds && (
                              <OddsProgressBar
                                variant="determinate"
                                value={parseFloat(item.odds)}
                              />
                            )}
                            <Typography
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "transparent",
                                px: 0.2,
                                whiteSpace: "nowrap",
                                color: "white",
                                fontSize: "10px",
                                py: 0,
                              }}
                            >
                              {item.odds
                                ? `ODDS: ${item.odds}`
                                : item.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
          </>

          <>
            {activeCategory === "Casino" && filteredCasinoItems.length > 0 && (
              <>
                <Typography
                  align="left"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    ml: 1,
                    color: "white",
                    mb: 1,
                  }}
                >
                  <span
                    style={{
                      color: "#61a9ff",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    |
                  </span>{" "}
                  Casino
                </Typography>
                <Grid container spacing={2}>
                  {filteredCasinoItems.map((item) => (
                    <Grid item xs={6} key={item.id}>
                      <Card
                        sx={{
                          position: "relative",
                          bgcolor: "transparent",
                          border: "none",
                          borderRadius: "10px",
                          boxShadow: "none",
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="180"
                          image={item.imgSrc}
                          alt={item.game}
                          sx={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                            mb: 2,
                          }}
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>

          <>
            {(activeCategory === "Original" ||
              activeCategory === "Slots" ||
              activeCategory === "Sports" ||
              activeCategory === "Fishing" ||
              activeCategory === "Rummy") &&
              filteredGames.length > 0 && (
                <>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      ml: 1,
                      color: "white",
                      mb: 1,
                    }}
                  >
                    <span
                      style={{
                        color: "#61a9ff",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      |
                    </span>{" "}
                    {activeCategory}
                  </Typography>
                  <Grid container spacing={2}>
                    {filteredGames.map((item) => (
                      <Grid item xs={4} key={item.id} sx={{ border: "none" }}>
                        <Card
                          sx={{
                            position: "relative",
                            bgcolor: "transparent",
                            border: "none",
                            borderRadius: "10px",
                            boxShadow: "none",
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="160"
                            image={item.image}
                            alt={item.name}
                            sx={{
                              border: "5px solid pink",
                              borderRadius: "10px",
                              objectFit: "cover",
                              width: "90%",
                              height: "100%",
                            }}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
          </>
        </Grid>
      </Container>
    </Mobile>
  )
}

export default GamesGrid
