import React from "react";
import BottomNavigationArea from "../Components/BottomNavigation";
import VipMain from "../Components/VipMain";
const Wallet = () => {
  return (
    <div>
      <VipMain>{/* <BottomNavigationArea /> */}</VipMain>
    </div>
  );
};

export default Wallet;
