
// **************LocalHost******************

// export const domain = 'http://localhost:4000';
// export const wssdomain = 'ws://localhost:4000';


// **************Production******************

export const domain = 'https://wealthwin.site';
export const wssdomain = 'wss://wealthwin.site';