import React, { useState, useRef, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domain } from "../Components/config";
import Mobile from "./Mobile";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Rebate = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [todayRebateAmount, setTodayRebateAmount] = useState(0);
  const [totalRebateAmount, setTotalRebateAmount] = useState(0);
  const [rebatePercentage, setRebatePercentage] = useState(0);
  const [rebateHistory, setRebateHistory] = useState([]);
  const tabRefs = useRef([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // Scroll the active tab into view
    if (tabRefs.current[tabValue]) {
      tabRefs.current[tabValue].scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest"
      });
    }
  }, [tabValue]);

  const tabsData = [
    {
      icon: '/assets/images/1-removebg-preview.png',
      icon1: '/assets/images/1.png',
      label: "All",
      title: "All-Total betting rebate",
    },
    {
      icon: '/assets/images/2 (1).png',
      icon1: '/assets/images/2 (2).png',
      label: "Lottery",
      title: "Lottery-Total betting rebate",
    },
    {
      icon: '/assets/images/4-removebg-preview.png',
      icon1: '/assets/images/4.png',
      label: "Casino",
      title: "Casino-Total betting rebate",
    },
    {
      icon: '/assets/images/6-removebg-preview.png',
      icon1: '/assets/images/6.png',
      label: "Rummy",
      title: "Rummy-Total betting rebate",
    },
    {
      icon: '/assets/images/5 (1).png',
      icon1: '/assets/images/5 (2).png',
      label: "Slots",
      title: "Slots-Total betting rebate",
    },
  ];

  useEffect(() => {
    const fetchRebateData = async () => {
      try {
        const response = await axios.get(`${domain}/rebate`, {
          withCredentials: true,
        });
        const {
          todayRebateAmount,
          totalRebateAmount,
          rebatePercentage,
          rebateHistory,
        } = response.data;
        setTodayRebateAmount(todayRebateAmount);
        setTotalRebateAmount(totalRebateAmount);
        setRebatePercentage(rebatePercentage);
        setRebateHistory(rebateHistory);
      } catch (error) {
        console.error("Error fetching rebate data:", error);
      }
    };

    fetchRebateData();
  }, []);

  return (
    <Mobile>
      <Box
        sx={{
          bgcolor: "#22275B",
          minHeight: "100vh",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "#2B3270",
            padding: "4px 8px",
            color: "white",
          }}
        >
          <Grid item container alignItems="center" justifyContent="center">
            <Grid item xs={2}>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ color: "white", ml: -5 }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  flexGrow: 1,
                  textAlign: "center",
                  mr: 8,
                }}
              >
                Rebate
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth" // Allow scrolling if needed
          scrollButtons="auto" // Show scroll buttons if needed
          sx={{
            mt: 1,
            bgcolor: "transparent",
            overflowX: "auto", // Enable horizontal scrolling
            "& .MuiTabs-flexContainer": {
              justifyContent: "flex-start", // Adjust alignment as needed
            },
          }}
        >
          {tabsData.map((tab, index) => (
            <Tab
              key={index}
              icon={
                <img
                  src={tabValue === index ? tab.icon : tab.icon1}
                  alt={tab.label}
                  style={{ width: "24px", height: "24px" }}
                />
              }
              label={tab.label}
              ref={(el) => (tabRefs.current[index] = el)}
              sx={{
                fontSize: "0.7rem",
                color: tabValue === index ? "#ffffff" : "#ACAFC2",
                bgcolor: tabValue === index ? "#2A9BF3" : "#2B3270",
                borderRadius: "10px",
                marginLeft: "5px",
                border: "none",
                overflow: "hidden",
                whiteSpace: "nowrap",
                "&.Mui-selected": { color: "#ffffff" },
                "&:hover": {
                  bgcolor: tabValue === index ? "#2A9BF3" : "#2B3270",
                  color: tabValue === index ? "#ffffff" : "#ACAFC2",
                },
              }}
            />
          ))}
        </Tabs>

        <Box sx={{ mt: 2, px: 2 }}>
          <Card
            variant="outlined"
            sx={{
              p: 2,
              bgcolor: "#2B3270",
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 2, color: "white", textAlign: "left" }}
            >
              {tabsData[tabValue].title}
            </Typography>
            <CardContent sx={{ p: 0 }}>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <Box
                  component="span"
                  sx={{
                    border: "1px solid #2981F2",
                    borderRadius: "5px",
                    px: 1,
                    py: 0.5,
                    mr: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VerifiedUserIcon sx={{ color: "#2981F2", mr: 0.5 }} />
                  <Typography
                    variant="caption"
                    sx={{ color: "#2981F2", fontWeight: "bold" }}
                  >
                    Real-time count
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 2, color: "white", textAlign: "left" }}
              >
                <AccountBalanceWalletOutlinedIcon
                  sx={{ fontSize: 32, color: "#2981F2", verticalAlign: "middle", mr: 1 }}
                />
                {todayRebateAmount.toFixed(2)}
              </Typography>
              <Box
                sx={{ bgcolor: "#374992", borderRadius: "8px", p: 1, mb: 2 }}
              >
                <Typography variant="body2" sx={{ color: "#ACAFC2", textAlign: "left" }}>
                  Upgrade VIP level to increase rebate rate
                </Typography>
              </Box>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={6}>
                  <Box sx={{ bgcolor: "#374992", borderRadius: "8px", p: 2, textAlign: "left" }}>
                    <Typography variant="body2" sx={{ color: "#ACAFC2" }}>
                      Rebate rate
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "#FF4136" }}
                    >
                      {rebatePercentage}%
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ bgcolor: "#374992", borderRadius: "8px", p: 2, textAlign: "left" }}>
                    <Typography variant="body2" sx={{ color: "#ACAFC2" }}>
                      Total rebate
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "#F24136" }}
                    >
                      {totalRebateAmount.toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography
                variant="body2"
                sx={{ mb: 2, fontStyle: "italic", color: "#ACAFC2" }}
              >
                Automatic code washing at 01:00:00 every morning
              </Typography>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "#454456",
                  color: "#fff",
                  borderRadius: "25px",
                  textTransform: "none",
                  height: "48px",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  "&:hover": { bgcolor: "#454456" },
                }}
              >
                One-Click Rebate
              </Button>
            </CardContent>
          </Card>

          <Typography
            variant="h6"
            sx={{
              textAlign: "left",
              fontWeight: "bold",
              mt: 4,
              mb: 2,
              color: "white",
            }}
          >
            <Box
              component="span"
              sx={{
                bgcolor: "#2989F2",
                mr: 1,
                borderRadius: "2px",
                width: "4px",
                height: "20px",
                display: "inline-block",
              }}
            ></Box>
            Rebate Record
          </Typography>

          <Box sx={{ mt: 1 }}>
            {rebateHistory.length > 0 ? (
              rebateHistory.map((record) => (
                <Card
                  key={record._id}
                  variant="outlined"
                  sx={{
                    mb: 2,
                    p: 2,
                    bgcolor: "#374992",
                    borderRadius: "12px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ color: "white" }}>
                        Rebate Amount
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#ACAFC2" }}
                      >
                        {record.rebateAmount.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ color: "white" }}>
                        Wallet Amount After Rebate
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#ACAFC2" }}
                      >
                        {record.walletAmountAfterRebate.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ color: "white" }}>
                        Level Awarded
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#ACAFC2" }}
                      >
                        {record.levelAwarded}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ color: "white" }}>
                        Date
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {new Date(record.date).toLocaleDateString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <img
                  src="/assets/Search-rafiki.png" // Replace with the correct path to your image
                  alt="No data available"
                  style={{ width: "50%", marginBottom: "10px" }} // Adjust size and spacing as needed
                />
                <Typography variant="body1" sx={{ color: "white" }}>
                  No rebate records available
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <br/>
      <br/>
      <br/>
    </Mobile>
  );
};

export default Rebate;