import React, { useEffect, useState } from "react";
import {Button, useMediaQuery} from "@mui/material";
import "./Attendance.css";
import CoinBox from "./CoinBox.jsx";
import gift from "../../assets/gift.png";
import axios from "axios";
import { domain } from "../config";
import { Link } from 'react-router-dom';
function Attendance() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  // Use the theme's breakpoint to determine if the screen is small
  const isSmallScreen = useMediaQuery('(max-width:350px)');
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${domain}/user`, { withCredentials: true });
      setUser(response.data.user);
      setError(null);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const claimBonus = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${domain}/attendance`, {}, { withCredentials: true });
      alert(response.data.msg);

      // Update the UI immediately
      setUser((prevUser) => ({
        ...prevUser,
        consecutiveDays: response.data.consecutiveDays,
        totalBonusAmount: response.data.totalBonusAccumulated,
      }));
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg);
        if (err.response.data.requiredDeposit) {
          alert(`Required deposit: ${err.response.data.requiredDeposit}`);
        }
      } else {
        alert("An error occurred while claiming the bonus");
      }
    } finally {
      setLoading(false);
    }
  };

  const bonusStructure = [
    { day: 1, requiredDeposit: 200, bonus: 5 },
    { day: 2, requiredDeposit: 1000, bonus: 18 },
    { day: 3, requiredDeposit: 3000, bonus: 100 },
    { day: 4, requiredDeposit: 10000, bonus: 200 },
    { day: 5, requiredDeposit: 20000, bonus: 400 },
    { day: 6, requiredDeposit: 100000, bonus: 3000 },
    { day: 7, requiredDeposit: 200000, bonus: 7000 },
  ];

  const getNextClaimableDay = () => {
    if (!user) return 1;
    return ((user.consecutiveDays || 0) % 7) + 1;
  };

  return (
    <div className="outerContainer">
      <div className="bannerBox">
        <div className="content">
          <div className="contentOne">
            <h2>Attendance Bonus</h2>
            <p style={{fontSize: "15px"}}>
              Get <b>rewards</b> based on consecutive<br/> login days!
            </p>
          </div>
          <div className="contentTwo">
            <span style={{ fontSize: "12px" }}>Attended Consecutively</span>
            <span>
              <b>{loading ? "Loading" : user?.consecutiveDays || 0}</b> Days
            </span>
          </div>
          <div className="contentThree">
            <p>Accumulated</p>
            <h1 style={{marginBottom:2}}>{loading ? "Loading" : user?.totalBonusAmount?.toFixed(2) || "0.00"}</h1>
          </div>
          <div style={{display:"flex",justifyContent:"space-between"}}>
        <Link to="/game-rules" style={{ textDecoration: "none" }}>
          <Button variant="contained" sx={{color:"white",background:"linear-gradient(to right, #FF8C3D, #FFAB3F)",borderRadius:"20px",fontSize:isSmallScreen ?"12px":''}}>Game Rules</Button></Link>
       <Button variant="contained" sx={{fontSize:isSmallScreen ?"12px":'',color:"white",background:"linear-gradient(to right, #FF8C3D, #FFAB3F)",borderRadius:"20px"}}>Attendance History</Button>
        </div>
        </div>
       
      </div>
      <div className="cardbox">
        {bonusStructure.map((bonus, index) => (
          index < 6 ? (
            <CoinBox
              key={bonus.day}
              coinboxAmount={`₹${bonus.bonus.toFixed(2)}`}
              coinboxDay={`${bonus.day} Day`}
              onClick={claimBonus}
              disabled={loading || getNextClaimableDay() !== bonus.day}
            />
          ) : (
            <div
              id="coinbox-container"
              key={bonus.day}
              onClick={getNextClaimableDay() === 7 ? claimBonus : undefined}
              style={{
                cursor: getNextClaimableDay() === 7 ? "pointer" : "not-allowed",
              }}
            >
              <div id="coinbox-image">
                <img src={gift} alt="Gift" />
              </div>
              <div id="coinbox-content">
                <div id="coinbox-amount" style={{ color: "white" }}>
                  ₹{bonus.bonus.toFixed(2)}
                </div>
                <div id="coinbox-day" style={{ color: "#acafc2" }}>
                  {bonus.day} Day
                </div>
              </div>
            </div>
          )
        ))}
        <Button
          variant="contained"
          className="attendanceButton"
          onClick={claimBonus}
          disabled={loading}
          sx={{
            width: "90%",
            height: "35px",
            borderRadius: "20px",
            background: "linear-gradient(to right,#2A9FF3 , #2A9FF3)",
            cursor: "pointer",
            border: "none",
            fontSize: "0.8em",
            marginBottom: "120px",
            color: "#ffffff",
            textTransform: "initial",
          }}
        >
          {loading ? "Loading..." : "Claim Attendance Bonus"}
        </Button>
        <br/>
        <br/>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default Attendance;