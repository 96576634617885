import React, { useState, useEffect } from "react";
import Mobile from "../Components/Mobile";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Grid,
  Box,
  Button,
  Drawer,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CalendarDrawer from "./CalendarDrawer";
import axios from "axios";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { domain } from "../Components/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "./TransactionCard.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const FilterDrawer = ({ isOpen, onClose, onConfirm }) => {
  const options = ["All", "Bet", "Agent Commission", "Deposit", "Withdraw"];
  const [selectedOption, setSelectedOption] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));


  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleConfirm = () => {
    onConfirm(selectedOption);
    onClose();
  };

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose} sx= {{
      "& .MuiDrawer-paper": {
         width: "100%",
         height: "auto",
         margin: "0 auto",
         maxWidth: isSmallScreen ? "425px" : "396px",
         backgroundColor: "#22275b",
         color: "black",
         borderTopLeftRadius: "16px",
         borderTopRightRadius: "16px",
       },
   }}>
       <Box
        sx={{
          padding: "16px",
          backgroundColor: "#2b3270",
          height: "auto",
          maxHeight: "50vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ color: "#888", cursor: "pointer", fontWeight: "bold" }}
            onClick={onClose}
          >
            Cancel
          </Typography>
          <Typography
            sx={{ color: "#61a9ff", fontWeight: "bold", cursor: "pointer" }}
            onClick={handleConfirm} // Apply the filter on confirm
          >
            Confirm
          </Typography>
        </Box>
        <Box>
          {options.map((option, index) => (
            <Button
              key={index}
              sx={{
                padding: "12px",
                marginBottom: "8px",
                width: "100%",
                textAlign: "left",
                borderRadius: "4px",
                backgroundColor: "#ffffff",
                borderBottom: "1px solid #e0e0e0",
                backgroundColor:
                  selectedOption === option ? "#374992" : "#2b3270",
                color: selectedOption === option ? "white" : "#ccc",
                "&:hover": {
                  backgroundColor: "#374992",
                },
              }}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </Button>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};
const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const formattedDate = date.toLocaleDateString(); 
  const formattedTime = date.toLocaleTimeString(); 

  return `${formattedDate} ${formattedTime}`;
};

const TransactionCard = ({ transaction }) => {
  let headerStyle = {};
  let headerText = "";

  switch (transaction.type) {
    case "commission":
      headerStyle = { backgroundColor: "#374992", color: "#FFFFFF" }; 
      headerText = "Agent Commission";
      break;
    case "WingoBet":
    case "K3Bet":
    case "TRXBet":
    case "5DBet":
      headerStyle = { backgroundColor: "#374992", color: "#FFFFFF" }; // Light Blue
      headerText = "Bet";
      break;
    case "deposit":
      headerStyle = { backgroundColor: "#374992", color: "#FFFFFF" }; // Green
      headerText = "Deposit";
      break;
    case "withdraw":
      headerStyle = { backgroundColor: "#374992", color: "#FFFFFF" }; // Red
      headerText = "Withdraw";
      break;
    default:
      headerStyle = { backgroundColor: "#374992", color: "#ffffff" }; 
      headerText = "Transaction";
  }

  const formattedDateTime = formatDateTime(transaction.date);

  return (
    <div className="transaction-card">
      <div className="transaction-header" style={headerStyle}>
        {headerText}
      </div>
      <div className="transaction-body">
        <div className="transaction-detail">
          <span className="transaction-label">Detail</span>
          <span className="transaction-value">{headerText}</span>
        </div>
        <div className="transaction-time">
          <span className="transaction-label">Date & Time</span>
          <span className="transaction-value">{formattedDateTime}</span>
        </div>
        <div className="transaction-balance">
          <span className="transaction-label">Balance</span>
          <span className="transaction-value">{transaction.amount}</span>
        </div>
      </div>
    </div>
  );
};

const TransactionHistoryMain = () => {
  const navigate = useNavigate();
  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [transactions, setTransactions] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });

  const categoryMap = {
    All: "All",
    Bet: "Bet",
    "Agent Commission": "AgentCommission",
    Deposit: "Deposit",
    Withdraw: "Withdraw",
  };

  useEffect(() => {
    fetchTransactions(categoryMap[selectedFilter], selectedDateRange);
  }, [selectedFilter, selectedDateRange]);

  const fetchTransactions = async (category, dateRange) => {
    try {
      const response = await axios.get(`${domain}/transaction-history`, {
        params: {
          category: category,
          startDate: dateRange.start,
          endDate: dateRange.end,
        },
        withCredentials: true,
      });
      // Assuming response.data is an array of transactions
      let filteredTransactions = response.data;

      // Apply date range filter if dates are selected
      if (dateRange.start && dateRange.end) {
        filteredTransactions = response.data.filter((transaction) => {
          const transactionDate = new Date(transaction.date);
          const startDate = new Date(dateRange.start);
          const endDate = new Date(dateRange.end);

          return transactionDate >= startDate && transactionDate <= endDate;
        });
      }

      console.log("Filtered transactions:", filteredTransactions);
      setTransactions(filteredTransactions);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]); // Ensure the transactions array is reset on error
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const toggleCalendarDrawer = (open) => {
    setCalendarDrawerOpen(open);
  };

  const toggleFilterDrawer = (open) => {
    setFilterDrawerOpen(open);
  };

  const handleFilterConfirm = (filter) => {
    if (filter !== null) {
      console.log(`Filter selected: ${filter}`);
      setSelectedFilter(filter);
    }
  };

  const handleDateRangeSelect = (dateRange) => {
    console.log("Date range selected:", dateRange);
    setSelectedDateRange(dateRange);
  };

  const formatDateRangeDisplay = () => {
    const { start, end } = selectedDateRange;
    if (start && end) {
      return `${new Date(start).toLocaleDateString()} - ${new Date(
        end
      ).toLocaleDateString()}`;
    } else if (start) {
      return new Date(start).toLocaleDateString();
    }
    return "Choose a date";
  };

  return (
    <div>
      <Mobile>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
        >
          <Box >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#2b3270",
                padding: "4px 8px",
                color: "white",
              }}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item xs={2}>
                  <IconButton
                    onClick={handleBackClick}
                    sx={{ color: "white", ml: -5 }}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      flexGrow: 1,
                      textAlign: "center",
                      mr: 8,
                    }}
                  >
                    Transaction History
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Box
              sx={{
                padding: "16px",
                display: "flex",
                gap: "12px",
                backgroundColor: "#22275b",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => toggleFilterDrawer(true)}
                sx={{
                  flex: 1,
                  width: "48%",
                  height: "2.8rem",
                  backgroundColor: "#2b3270",
                  textTransform: "none",
                  display: "flex",
                  fontSize: "0.68rem",
                  justifyContent: "space-between",
                  padding: "0 10px",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                {selectedFilter}               
              <KeyboardArrowDownOutlinedIcon />
              </Button>
              <Button
                variant="outlined"
                onClick={() => toggleCalendarDrawer(true)}
                sx={{
                  flex: 1,
                  justifyContent: "space-between",
                  backgroundColor: "#2b3270",
                  textTransform: "none",
                  display: "flex",
                  fontSize: "0.68rem",
                  padding: "0 10px",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
              >
                {formatDateRangeDisplay()} 
                <KeyboardArrowDownOutlinedIcon />
              </Button>
            </Box>
          </Box>

          <Box
            flexGrow={1}
            sx={{ padding: "16px", backgroundColor: "#22275b" }}
          >
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <TransactionCard key={index} transaction={transaction} />
              ))
            ) : (
              <Typography sx={{ color: "white"}}>No data available.</Typography>
            )}
          </Box>
          <br/>
          <br/>
          <br/>
        </Box>
      </Mobile>

      {/* Filter Drawer */}
      <FilterDrawer
  isOpen={filterDrawerOpen}
  onClose={() => toggleFilterDrawer(false)}
  onConfirm={handleFilterConfirm}
/>


      {/* Calendar Drawer */}
      <CalendarDrawer
        isOpen={calendarDrawerOpen}
        onClose={() => toggleCalendarDrawer(false)}
        onRangeSelect={handleDateRangeSelect}
      />
    </div>
  );
};

export default TransactionHistoryMain;