import React from "react"
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Grid,
  TableRow,
  Button,
} from "@mui/material"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { useNavigate } from "react-router-dom"
import WarningIcon from "@mui/icons-material/Warning"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic"
import Mobile from "../Components/Mobile"

// Bonus data array
const bonusData = [
  { winningRate: "10X-19X", betAmount: "₹30-₹99999", bonus: "₹50.00" },
  { winningRate: "20X-29X", betAmount: "₹30-₹99999", bonus: "₹100.00" },
  { winningRate: "30X-39X", betAmount: "₹30-₹99999", bonus: "₹200.00" },
  { winningRate: "40X-59X", betAmount: "₹30-₹99999", bonus: "₹300.00" },
  { winningRate: "60X-999999X", betAmount: "₹30-₹99999", bonus: "₹500.00" },
]

const Rule = () => {
  const navigate = useNavigate()
  const handleRedirect = () => {
    navigate(-1)
  }

  return (
    <div>
      <Mobile>
        <Box
          sx={{
            bgcolor: "#22275B",
            minHeight: "100vh",
            p: 0,
            maxWidth: "sm",
            mx: "auto",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "#2B3270", // Updated background color
              padding: "4px 8px",
              color: "white", // Updated text color
            }}
          >
            <Grid item container alignItems="center" justifyContent="center">
              <Grid item xs={2}>
                <IconButton
                  onClick={() => navigate(-1)}
                  sx={{ color: "white", ml: -5 }} // Updated icon color
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "white", // Updated text color
                    flexGrow: 1,
                    textAlign: "center",
                    mr: 8,
                  }}
                >
                  Rules
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              p: 2,
              mb: 2,
              position: "relative",
              color: "white",
              overflow: "hidden",
            }}
          >
            {/* Background Image */}
            <Box
              component="img"
              src="../../assets/superJackpotRulebg-809c0012.png"
              alt="Super Jackpot Banner"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
              }}
            />

            {/* Content */}
            <Box sx={{ position: "relative", zIndex: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={7} sx={{ textAlign: "left" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    Super Jackpot
                  </Typography>
                  <Typography variant="caption" paragraph>
                    When you win the Super Jackpot in the game, you can get
                    additional platform bonuses, and the bonuses will be
                    distributed to you according to the multiple of the winning
                    prize.
                  </Typography>
                </Grid>
              </Grid>
              <Box
                sx={{
                  bgcolor: "rgba(252,69,0,0.4)",
                  p: 0.5,
                  borderRadius: 1,
                  display: "flex",
                  mb: -1,
                  alignItems: "center",
                }}
              >
                <WarningIcon sx={{ mr: 1 }} />
                <Typography variant="caption">
                  Warning: Please claim all bonuses before the event ends, after
                  the event ends, you will lose the chance to get the bonus.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ p: 1.2, mb: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "white",
              }}
            >
              <img
                src="/assets/Database.png"
                style={{
                  mr: 1,
                  color: "#61A9FF",
                  height: "20px",
                  marginRight: "9px",
                }}
              />{" "}
              Bonus
            </Typography>
            <TableContainer
              sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
            >
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#374992" }}>
                    <TableCell sx={{ color: "#ffffff" }}>
                      Winning rate
                    </TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Bet amount</TableCell>
                    <TableCell sx={{ color: "#ffffff" }}>Bonus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bonusData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ bgcolor: index % 2 === 0 ? "#2B3270" : "#22275B" }}
                    >
                      <TableCell sx={{ color: "#BA9138" }}>
                        {row.winningRate}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {row.betAmount}
                      </TableCell>
                      <TableCell sx={{ color: "#D23838" }}>
                        {row.bonus}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            sx={{
              p: 1,
              mb: 2,
              bgcolor: "#2B3270",
              borderRadius: "10px",
              margin: 1,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#ACA4AB",
                display: "inline-flex",
                alignItems: "left",
                textAlign: "left",
              }}
            >
              <PlayArrowIcon sx={{ fontSize: "20px", color: "#61A9FF" }} />
              &nbsp;All event interpretation rights belong to the platform. If
              you have any questions, please contact customer service now.
            </Typography>
          </Box>
          <Button
            variant="contained"
            // fullWidth
            startIcon={
              <img
                src="/assets/Ghost icon.png"
                style={{ width: 24, height: 24 }}
              />
            }
            sx={{
              width: "95%",
              bgcolor: "#61A9FF",
              p: 5,
              mt: 2,
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#61A9FF",
              },
              py: 1,
              borderRadius: 10,
            }}
            onClick={() => navigate("/customer-service")}
          >
            Contact customer service
          </Button>
        </Box>
        <br />
        <br />
        <br />
      </Mobile>
    </div>
  )
}

export default Rule
