import React, { useEffect, useState } from "react"
import Mobile from "../Components/Mobile"
import IconButton from "@mui/material/IconButton"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import SmsIcon from "@mui/icons-material/Sms"
import DownloadIcon from "@mui/icons-material/Download"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"
import { CSSTransition } from "react-transition-group"
import { makeStyles } from "@mui/styles"
import {
  Paper,
  Typography,
  Button,
  Grid,
  Box,
  List,
  Chip,
  Tabs,
  ListItem,
  Container,
  LinearProgress,
  Avatar,
  styled,
} from "@mui/material"
import { Whatshot } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import Two from "../Components/Two"
import Games from "../Components/Games"
import Stage from "../Components/Stage"
import BottomHome from "./BottomHome"
import LoadingLogo from "./LoadingLogo"
import DepositModal from "../Pages/depositModal" // Adjust the path as needed
import NotificationModal from "../Components/NotificationPopup"
// import DepositModal from '../Pages/depositModal';
const Tab = styled(Container)(({ theme }) => ({
  textAlign: "center",
  borderRadius: "10px",
  padding: theme.spacing(1),
  overflow: "hidden",
}))

const Loteria = [
  {
    id: 1,
    imgSrc: "/assets/wingo.png",
    game: "Win Go",
    path: "/timer/1min",
  },
  {
    id: 2,
    imgSrc: "/assets/k3.png",
    game: "k3",
    path: "/k3/1min",
  },
  {
    id: 3,
    imgSrc: "../../games/assets/TRX.png",
    game: "trx",
    path: "/trx/1min",
  },
]

const profitList = [
  {
    name: "Mem***EFJ",
    rank: "NO1",
    rankImg: "/assets/no1-5c6f8e80.png",
    price: "₹4,105,048.82",
    avatar: "/assets/avatar-ea3b8ee9.png",
  },
  {
    name: "Mem***DEC",
    rank: "NO2",
    rankImg: "/assets/no2-1683c744.png",
    price: "₹721,223.44",
    avatar: "/assets/avatar-ea3b8ee9.png",
  },
  {
    name: "Mem***HVK",
    rank: "NO3",
    rankImg: "/assets/no3-95e1b4d0.png",
    price: "₹533,333.20",
    avatar: "/assets/avatar-ea3b8ee9.png",
  },
  {
    name: "Mem***XTT",
    rank: "NO4",
    price: "₹454,093.24",
    avatar: "/assets/avatar-ea3b8ee9.png",
  },
  {
    name: "Mem***EME",
    rank: "NO5",
    price: "₹4,322,311.72",
    avatar: "/assets/avatar-ea3b8ee9.png",
  },
]

const useStyles = makeStyles({
  tabContainer: {
    transitionTimingFunction: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
    transitionDuration: "360ms",
    transform: "translate3d(0px, 0px, 0px)",
  },
  activeTab: {
    color: "rgb(22, 119, 255)",
  },
  inactiveTab: {
    display: "none",
  },
  tabItem: {
    display: "flex",
    alignItems: "center",
  },
  activeLine: {
    transition: "300ms",
    width: "0px",
    height: "3px",
    transform: "translate3d(41px, 0px, 0px)",
    backgroundColor: "rgb(22, 119, 255)",
  },
})

const StyledPaper = styled(Paper)(({ theme }) => ({
  transition: "transform 0.5s ease-in-out",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const win = [
  {
    txt: "Mem***GGD",
    image: "/assets/7-00479cfa.png",
    txt2: "28.09",
    image1: "/assets/Coq game.png",
  },
  {
    txt: "Mem***DHF",
    image: "/assets/8-ea087ede.png",
    txt2: "39.03",
    image1: "/assets/Cricket.png",
  },
  {
    txt: "Mem***SKL",
    image: "/assets/9-6d772f2c.png",
    txt2: "13.36",
    image1: "/assets/Evolution.png",
  },
  {
    txt: "Mem***PID",
    image: "/assets/13-5676d43f.png",
    txt2: "16.90",
    image1: "/assets/Jili Game.png",
  },
  {
    txt: "Mem***JYR",
    image: "/assets/8-ea087ede.png",
    txt2: "69.03",
    image1: "/assets/U game.png",
  },
  {
    txt: "Mem***MKL",
    image: "/assets/9-6d772f2c.png",
    txt2: "39.03",
    image1: "/assets/Rummy.png",
  },
]
const Home = ({ children }) => {
  const [winners, setWinners] = useState(win)
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", setVh)
    setVh()

    return () => window.removeEventListener("resize", setVh)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setWinners((prevWinners) => {
        const lastWinner = prevWinners[prevWinners.length - 1]
        const newWinners = [lastWinner, ...prevWinners.slice(0, -1)]
        return newWinners
      })
    }, 2000) // Adjust the timing as needed
    return () => clearInterval(interval)
  }, [])

  const lastWinner = winners[winners.length - 1]
  const otherWinners = winners.slice(0, -1)

  const images = [
    {
      id: 1,
      src: "assets/images/dragon1.jpg",
      alt: "First Image",
    },
    {
      id: 2,
      src: "assets/images/dragon2.jpg",
      alt: "Second Image",
    },
    {
      id: 3,
      src: "assets/images/dragon3.jpg",
      alt: "Third Image",
    },
  ]

  const [currentIndex, setCurrentIndex] = React.useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 3000) // Change slide every 3 seconds

    return () => clearInterval(interval)
  })

  // const imageUrls = [
  //   "assets/images/gamecategory_20231215033613klhe.png",
  //   "assets/images/gamecategory_202312150336204mtb.png",
  //   "assets/images/gamecategory_20231215033607yi17.png",
  //   "assets/images/gamecategory_20231215033600k8os.png",
  //   "assets/images/gamecategory_20231215033554mpgb.png",
  //   "assets/images/gamecategory_20231215033528g3gt.png",
  //   "assets/images/gamecategory_2023121503353389nc.png",
  //   "assets/images/gamecategory_202312150336366phx.png",
  // ];

  const [subtitles] = useState([
    "Lottery",
    "Slots",
    "Sports",
    "Casino",
    "PVC",
    "Finishing",
    "Mini games",
    "Popular",
  ])
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }))
  // const imageUrl = "assets/images/lottery-7b8f3f55.png";

  const navigate = useNavigate()

  // const handleClick = () => {
  //   navigate("/head"); // Navigate to the specified link
  // };
  // const handleClick1 = () => {
  //   navigate("/k3"); // Navigate to the specified link
  // };
  // const handleClick2 = () => {
  //   navigate("/trx"); // Navigate to the specified link
  // };

  // const [activeTab, setActiveTab] = useState(0); // Add this line

  // const handleDownload = () => {
  //   // Programmatically click the hidden anchor tag
  //   const link = document.createElement("a");
  //   link.href = `https://111club.online/abclottery.apk`; // Change this to the actual path of the APK file on your server
  //   link.download = "abclottery.apk";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const imageurl = [
    {
      image: "/assets/Dubai_games_banner.jpg",
      txt: "Our customer service never sends a link to the member, if you received a link from someone else it might be a scam.",
    },
    {
      image: "/assets/Dubai_games_banner2.jpg",
      txt: "Welcome to our TC Website our customer service never sends a link to the member.",
    },
    {
      image: "/assets/Dubai_games_banner3.jpg",
      txt: "Thankyou for visting our website and your value time,our website deals with many features,hope you enjoy",
    },
    {
      image: "/assets/Dubai_games_banner4.jpg",
      txt: "Thankyou for visting our website and your value time,our website deals with many features,hope you enjoy",
    },
    {
      image: "/assets/Dubai_games_banner5.jpg",
      txt: "Thankyou for visting our website and your value time,our website deals with many features,hope you enjoy",
    },
  ]

  // const TabIcon = ({ src, alt }) => (
  //   <img
  //     src={src}
  //     alt={alt}
  //     style={{
  //       width: "24px",
  //       height: "24px",
  //       marginRight: "8px",
  //     }}
  //   />
  // );

  const Header = styled(Box)(({ theme }) => ({
    overflow: "hidden",
    position: "relative",
    paddingLeft: "10px",
    paddingRight: "10px",
  }))
  const ImageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "auto",
    marginTop: "8px",
  }))

  const textArray = [
    "Welcome to the Dubai Games! Greetings, Gamers and Enthusiasts!",
    "The Dubai Games are here to provide excitement and fun.",
    "For your convenience and account safety, please ensure",
    "you fill in the genuine mobile number registered with your bank.",
    "Thank you for your cooperation and enjoy the games!",
  ]

  const [index, setIndex] = React.useState(0)
  const [inProp, setInProp] = React.useState(false)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setInProp(false)

      setTimeout(() => {
        setIndex((oldIndex) => {
          return (oldIndex + 1) % textArray.length
        })
        setInProp(true)
      }, 500) // This should be equal to the exit duration below
    }, 3000) // Duration between changing texts

    return () => clearInterval(timer)
  }, [])

  // const handleTabClick = async (path) => {
  //   navigate(path);
  // };

  const [isLoading, setIsLoading] = useState(true)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [currentTextIndex, setCurrentTextIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageurl.length)
    }, 2000)
    // Change image every 2 seconds
    return () => clearInterval(interval)
  }, [imageurl.length])
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % imageurl.length)
    }, 4000)
    // Change image every 2 seconds
    return () => clearInterval(interval)
  }, [imageurl.length])
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2000) // 2 seconds

    // Cleanup function to clear the timeout if the component unmounts before 2 seconds
    return () => clearTimeout(timer)
  }, [])

  // const numberOfItems = Loteria.length;

  // const [tabValue, setTabValue] = useState(2); // 'Mini games' tab is active by default

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    // Set a timeout to show the modal after 2 seconds
    const timer = setTimeout(() => {
      setShowModal(true)
    }, 2000)

    // Clean up the timeout if the component unmounts to avoid memory leaks
    return () => clearTimeout(timer)
  }, [])

  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false)
  const [isDepositModalOpen, setDepositModalOpen] = useState(false)

  const depositModalDismissedDate = localStorage.getItem("depositModalDismissedDate")
  const today = new Date().toDateString();

  useEffect(() => {
    // Automatically show NotificationModal 2 seconds after component mounts
    if( today !== depositModalDismissedDate){
    const timer = setTimeout(() => {
      setNotificationModalOpen(true)
    }, 2000) // 2 second delay
    
    return () => clearTimeout(timer) // Cleanup on unmount
  }
  }, [])

  const handleNotificationClose = () => {
    setNotificationModalOpen(false)
    // Open DepositModal when NotificationModal is closed
    setTimeout(() => setDepositModalOpen(true), 300) // Delay to ensure modal transition
  }

  const handleDepositModalClose = () => {
    setDepositModalOpen(false)
  }

  return (
    <div style={{ position: "relative" }}>
      <Mobile>
        {/* Other content */}
        <NotificationModal
          open={isNotificationModalOpen}
          onClose={handleNotificationClose}
        />
        <DepositModal
          open={isDepositModalOpen}
          onClose={handleDepositModalClose}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
            }}
          >
            <LoadingLogo websiteName="Dubai Games" />
          </div>
        )}
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
          sx={{
            backgroundColor: "#22275B",
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#2B3270",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#2B3270",
            },
          }}
        >
          <Box flexGrow={1}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#2B3270",
                padding: "2px 6px",
                color: "white",
              }}
            >
              <Grid item xs={6} textAlign="left">
                <img
                  src="/assets/DubaiGames.png"
                  alt="logo"
                  style={{ width: "180px", height: "60px", marginRight: "8px" }}
                />
              </Grid>
              <Grid item xs={6} textAlign="right">
                <IconButton
                  style={{ color: "#4162A9" }}
                  onClick={() => navigate("/messages")}
                >
                  <img
                    style={{ width: "25px", opacity: "65%" }}
                    src="/assets/mess2.png"
                    alt="notification"
                  />
                </IconButton>
              </Grid>
            </Grid>

            {/* //content */}

            <Header>
              <ImageWrapper>
                <img
                  src={imageurl[currentImageIndex].image}
                  alt={`Banner ${currentImageIndex + 1}`}
                  style={{
                    borderRadius: 10,
                    width: "100%",
                    height: "180px",
                  }}
                />
              </ImageWrapper>
            </Header>

            <Grid
              item
              sx={{
                backgroundColor: "#2B3270",
                margin: "10px",
                borderRadius: "20px",
                padding: "3px 10px", // Adjust padding to make room for the button and text
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden", // Ensure content stays within the box
              }}
            >
              <IconButton>
                <VolumeUpIcon sx={{ color: "#61A9FF" }} />
              </IconButton>

              <Box sx={{ flex: 1, overflow: "hidden", padding: "0 10px" }}>
                <CSSTransition
                  in={inProp}
                  timeout={500}
                  classNames="message"
                  unmountOnExit
                >
                  <Typography
                    sx={{
                      color: "#ccc",
                      fontSize: "12px",
                      fontWeight: 600,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      textAlign: "left",
                      overflow: "hidden",
                      WebkitLineClamp: 2, // Limits the text to 2 lines
                      lineClamp: 2, // Fallback for non-WebKit browsers
                      textOverflow: "ellipsis", // Adds "..." at the end of overflowed text
                    }}
                  >
                    {textArray[index]}
                  </Typography>
                </CSSTransition>
              </Box>

              <Button
                variant="contained"
                sx={{
                  background: "#61A9FF",
                  "&:hover": {
                    background: "#61A9FF",
                  },
                  borderRadius: "50px",
                  fontSize: "11px",
                  textTransform: "initial",
                  padding: "4px 12px", // Adjust padding for a more balanced look
                  color: "white",
                }}
              >
                <LocalFireDepartmentIcon />
                Details
              </Button>
            </Grid>

            {/* All game cards section */}

            <Games />

            {/* Winning Information */}

            <Box sx={{ maxWidth: 600, margin: "auto", padding: 1 }}>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <span
                style={{
                  color: "#61a9ff",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginRight: "4px",
                  marginTop: "-4px",
                  marginLeft: "8px"
                }}
              >
                |
              </span>{" "}
              <Typography
                variant="h6"
                color="#fff"
                fontFamily=" bahnschrift"
                fontWeight="bold"
                textAlign="left"
                marginRight= "4px"
              >
                Winning Information
              </Typography>
            </Grid>
            </Box>
            <Box sx={{ mt: -4, margin: 1 }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5px",
                  marginRight: "5px",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  columns={{ xs: 1, sm: 1, md: 1 }}
                >
                  {winners.map((item, index) => (
                    <Grid item xs={12} key={index} mb={0}>
                      <StyledPaper
                        sx={{ backgroundColor: "#2b3270", padding: 1 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <img
                              src={item.image}
                              alt=""
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "0.5px solid white",
                                margin: 2,
                              }}
                            />
                            <Typography
                              sx={{ color: "#fff", fontSize: "0.7rem" }}
                            >
                              {item.txt}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "right",
                              gap: 2,
                              minWidth: "200px",
                            }}
                          >
                            <img
                              src={item.image1}
                              alt=""
                              style={{
                                width: "60px",
                                height: "45px",
                                borderRadius: "10px",
                                background:
                                  "linear-gradient(to right, #ff8a80, #ffccbc)",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "left",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Receive ₹{item.txt2}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#acafc0",
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Winning Amount
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </StyledPaper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" alignItems="center" sx={{ marginLeft: "5px" }}>
              <Typography
                align="left"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  color: "white",
                }}
              >
                <span
                  style={{
                    color: "#61a9ff",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  |
                </span>{" "}
                Today's earnings chart
              </Typography>
            </Box>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginLeft: "5px",
                marginRight: "5px",
                marginTop: "65px",
                marginBottom: "30px",
                // maxWidth: "450px",
              }}
            >
              <Stage />
            </Grid>
            <BottomHome />
            <br />
            <br />
            <br />
            <br />
            <br />

            {/* content end */}
          </Box>

          {children}
        </Box>
      </Mobile>
    </div>
  )
}

export default Home
