import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Box,
  IconButton,
  Typography,
  Paper,
  TextField,
  Button,
} from "@mui/material"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { useParams } from "react-router-dom"
import { domain } from "./config"
import Mobile from "./Mobile"
import LoadingScreen from "./LoadingScreen"

const ChatZone = () => {
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState(null)
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState("")

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await axios.get(`${domain}/tickets/user/${ticketId}`, {
          withCredentials: true,
        })
        setTicket(response.data)
        setLoading(false)
      } catch (error) {
        console.error("Error fetching ticket:", error)
        setLoading(false)
      }
    }

    fetchTicket()
  }, [ticketId])

  const handleSendMessage = async () => {
    if (message.trim() === "") return

    try {
      await axios.post(
        `${domain}/tickets/user/${ticketId}/message`,
        { message },
        {
          withCredentials: true,
        }
      )
      setMessage("")
      // Re-fetch ticket data to update the messages
      const response = await axios.get(`${domain}/tickets/user/${ticketId}`, {
        withCredentials: true,
      })
      setTicket(response.data)
    } catch (error) {
      console.error("Error sending message:", error)
    }
  }

  if (loading) return <LoadingScreen />

  if (!ticket) return <Typography>No ticket found</Typography>

  return (
    <Mobile>
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        bgcolor="#22275b"
      >
        {/* Header */}
        <Box
          sx={{
            height: "10%",
            minHeight: 60,
            backgroundColor: "#2b3270",
            display: "flex",
            alignItems: "center",
            padding: "0 16px",
            boxShadow: "0px 1px 5px rgba(0,0,0,0.1)",
          }}
        >
          <IconButton
            sx={{ color: "white" }}
            onClick={() => window.history.back()}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              color: "white",
              textAlign: "center",
              flexGrow: 1,
              marginRight: 48, // To center the title considering the back button width
            }}
          >
            Chat
          </Typography>
        </Box>

        {/* Messages Section */}
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            overflowY: "scroll",
            // Hide scrollbar while allowing scrolling
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": {
              display: "none", // For Chrome, Safari, and Opera
            },
          }}
        >
          {ticket.messages.map((msg, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent={
                msg.sender === "Admin" ? "flex-start" : "flex-end"
              }
              sx={{ mb: 1 }}
            >
              <Paper
                sx={{
                  p: 1.5,
                  maxWidth: "70%",
                  backgroundColor:
                    msg.sender === "Admin" ? "#2b3270" : "#374992",
                  color: msg.sender === "Admin" ? "white" : "white",
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                  {msg.message}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ mt: 0.5, display: "block", textAlign: "right" }}
                >
                  {new Date(msg.timestamp).toLocaleString()}
                </Typography>
              </Paper>
            </Box>
          ))}
        </Box>

        {/* Input Area */}
        <Box
          sx={{
            height: "10%",
            minHeight: 60,
            padding: "8px 16px",
            backgroundColor: "#2b3270",
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #374992",
            color: "white",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage()
              }
            }}
            fullWidth
            sx={{
              mr: 1,
              bgcolor: "#374992",
              color: "white",
              borderRadius: "6px",
              "& .MuiOutlinedInput-root" :{
                color: "white"
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
          >
            Send
          </Button>
        </Box>
        <br/>
        <br/>
        <br/>
      </Box>
    </Mobile>
  )
}

export default ChatZone
