import React from "react";
import BottomNavigationArea from "../Components/BottomNavigation";
import DepositHistoryMain from "../Components/DepositHistoryMain";
const BetHistory = () => {
  return (
    <div>
      <DepositHistoryMain>
        <BottomNavigationArea />
      </DepositHistoryMain>
    </div>
  );
};

export default BetHistory;
