import React, { useState } from "react";
import { Box, Typography, IconButton,Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import Mobile from "./Mobile";

function ReceiveHistoryPage() {
  const navigate = useNavigate();


  const [activeButton, setActiveButton] = useState("weekly"); // Manage active button state

  return (
    <Mobile>
      {/* AppBar-like container */}
      <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#2B3270",
                padding: "4px 8px",
                color: "white",
              }}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => navigate(-1)}
                    sx={{ color: "white", ml: -5 }}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      flexGrow: 1,
                      textAlign: "center",
                      mr: 8,
                    }}
                  >
                Receive History
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

      <Box
  sx={{ backgroundColor: "#22275b", height: "100vh", padding: "16px" }}
>
  <Box
    sx={{ display: "flex", justifyContent: "space-between", marginTop: "32px" }}
  >
    <Box
      onClick={() => setActiveButton("weekly")}
      sx={{
        backgroundColor: activeButton === "weekly" ? "#2986f2" : "#374992",
        padding: "16px 0", // Increase padding for larger buttons
        borderRadius: "8px",
        width: "50%", // Ensure each button takes half of the container width
        color: activeButton === "weekly" ? "white" : "#ccc",
        textAlign: "center", // Center the text within the button
        cursor: "pointer",
      }}
    >
      weekly
    </Box>
    <Box
      onClick={() => setActiveButton("daily")}
      sx={{
        backgroundColor: activeButton === "daily" ? "#2986f2" : "#374992",
        padding: "16px 0", // Increase padding for larger buttons
        borderRadius: "8px",
        width: "50%", // Ensure each button takes half of the container width
        color: activeButton === "daily" ? "white" : "#ccc",
        textAlign: "center", // Center the text within the button
        cursor: "pointer",
      }}
    >
      daily
    </Box>
  </Box>
  <Box
    sx={{
      display: "flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems: "center",
      height: "50%",
      color: "grey",
    }}
  >
     <img
              src="/assets/Search-rafiki.png" // Replace with the correct path to your image
              alt="No data available"
              style={{
                width: "50%", 
                marginBottom: "5px"
              }} // Adjust size and spacing as needed
            />
            
    <Typography>No data</Typography>
  </Box>
</Box>

    </Mobile>
  );
}

export default ReceiveHistoryPage;