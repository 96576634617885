import React from "react";
import BottomNavigationArea from "../Components/BottomNavigation";
import WithDrawHistoryMain from "../Components/WithdrawHistoryMain";
const BetHistory = () => {
  return (
    <div>
      <WithDrawHistoryMain>
        {/* <BottomNavigationArea /> */}
      </WithDrawHistoryMain>
    </div>
  );
};

export default BetHistory;
