import React, { useState } from 'react';
import { Box, Typography, Grid, IconButton, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Mobile from '../Components/Mobile';

// Define tab items and rebate levels
const tabItems = [
  { name: 'Lottery', icon: '../../assets/images/2-removebg-preview.png',icon1:'../../assets/images/2.png' },
  { name: 'Casino', icon: '../../assets/images/4-removebg-preview.png',icon1:'../../assets/images/4.png'  },
  { name: 'Sports', icon: '../../assets/images/6-removebg-preview.png',icon1:'../../assets/images/6.png' },
  { name: 'Rummy', icon: '../../assets/images/3-removebg-preview.png',icon1:'../../assets/images/3.png' },
  { name: 'Slots', icon: '../../assets/images/5 (1).png',icon1:'../../assets/images/5 (2).png' }
];

const rebateLevels = {
    Lottery: [
      { level: 'L0', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.6%' },
        { description: '2 level lower level commission rebate', percentage: '0.18%' },
        { description: '3 level lower level commission rebate', percentage: '0.054%' },
        { description: '4 level lower level commission rebate', percentage: '0.016%' },
        { description: '5 level lower level commission rebate', percentage: '0.0048%' },
        { description: '6 level lower level commission rebate', percentage: '0.0014%' },
      ] },
      { level: 'L1', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.6%' },
        { description: '2 level lower level commission rebate', percentage: '0.18%' },
        { description: '3 level lower level commission rebate', percentage: '0.054%' },
        { description: '4 level lower level commission rebate', percentage: '0.016%' },
        { description: '5 level lower level commission rebate', percentage: '0.0048%' },
        { description: '6 level lower level commission rebate', percentage: '0.0014%' },
      ] },
      { level: 'L2', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.7%' },
        { description: '2 level lower level commission rebate', percentage: '0.21%' },
        { description: '3 level lower level commission rebate', percentage: '0.063%' },
        { description: '4 level lower level commission rebate', percentage: '0.018%' },
        { description: '5 level lower level commission rebate', percentage: '0.0056%' },
        { description: '6 level lower level commission rebate', percentage: '0.0017%' },
      ] },
      { level: 'L3', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.8%' },
        { description: '2 level lower level commission rebate', percentage: '0.24%' },
        { description: '3 level lower level commission rebate', percentage: '0.072%' },
        { description: '4 level lower level commission rebate', percentage: '0.022%' },
        { description: '5 level lower level commission rebate', percentage: '0.0066%' },
        { description: '6 level lower level commission rebate', percentage: '0.002%' },
      ] },
      { level: 'L4', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.9%' },
        { description: '2 level lower level commission rebate', percentage: '0.27%' },
        { description: '3 level lower level commission rebate', percentage: '0.081%' },
        { description: '4 level lower level commission rebate', percentage: '0.024%' },
        { description: '5 level lower level commission rebate', percentage: '0.0072%' },
        { description: '6 level lower level commission rebate', percentage: '0.0022%' },
      ] },
      { level: 'L5', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.0%' },
        { description: '2 level lower level commission rebate', percentage: '0.30%' },
        { description: '3 level lower level commission rebate', percentage: '0.090%' },
        { description: '4 level lower level commission rebate', percentage: '0.026%' },
        { description: '5 level lower level commission rebate', percentage: '0.0078%' },
        { description: '6 level lower level commission rebate', percentage: '0.0024%' },
      ] },
      { level: 'L6', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.1%' },
        { description: '2 level lower level commission rebate', percentage: '0.33%' },
        { description: '3 level lower level commission rebate', percentage: '0.099%' },
        { description: '4 level lower level commission rebate', percentage: '0.028%' },
        { description: '5 level lower level commission rebate', percentage: '0.0084%' },
        { description: '6 level lower level commission rebate', percentage: '0.0026%' },
      ] },
      { level: 'L7', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.2%' },
        { description: '2 level lower level commission rebate', percentage: '0.36%' },
        { description: '3 level lower level commission rebate', percentage: '0.108%' },
        { description: '4 level lower level commission rebate', percentage: '0.030%' },
        { description: '5 level lower level commission rebate', percentage: '0.0090%' },
        { description: '6 level lower level commission rebate', percentage: '0.0028%' },
      ] },
      { level: 'L8', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.3%' },
        { description: '2 level lower level commission rebate', percentage: '0.39%' },
        { description: '3 level lower level commission rebate', percentage: '0.117%' },
        { description: '4 level lower level commission rebate', percentage: '0.032%' },
        { description: '5 level lower level commission rebate', percentage: '0.0096%' },
        { description: '6 level lower level commission rebate', percentage: '0.0030%' },
      ] },
      { level: 'L9', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.4%' },
        { description: '2 level lower level commission rebate', percentage: '0.42%' },
        { description: '3 level lower level commission rebate', percentage: '0.126%' },
        { description: '4 level lower level commission rebate', percentage: '0.034%' },
        { description: '5 level lower level commission rebate', percentage: '0.0102%' },
        { description: '6 level lower level commission rebate', percentage: '0.0032%' },
      ] },
      { level: 'L10', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.5%' },
        { description: '2 level lower level commission rebate', percentage: '0.45%' },
        { description: '3 level lower level commission rebate', percentage: '0.135%' },
        { description: '4 level lower level commission rebate', percentage: '0.036%' },
        { description: '5 level lower level commission rebate', percentage: '0.0108%' },
        { description: '6 level lower level commission rebate', percentage: '0.0034%' },
      ] }
    ],
    Casino: [
      { level: 'L0', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.7%' },
        { description: '2 level lower level commission rebate', percentage: '0.21%' },
        { description: '3 level lower level commission rebate', percentage: '0.063%' },
        { description: '4 level lower level commission rebate', percentage: '0.018%' },
        { description: '5 level lower level commission rebate', percentage: '0.0056%' },
        { description: '6 level lower level commission rebate', percentage: '0.0017%' },
      ] },
      { level: 'L1', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.7%' },
        { description: '2 level lower level commission rebate', percentage: '0.21%' },
        { description: '3 level lower level commission rebate', percentage: '0.063%' },
        { description: '4 level lower level commission rebate', percentage: '0.018%' },
        { description: '5 level lower level commission rebate', percentage: '0.0056%' },
        { description: '6 level lower level commission rebate', percentage: '0.0017%' },
      ] },
      { level: 'L2', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.8%' },
        { description: '2 level lower level commission rebate', percentage: '0.24%' },
        { description: '3 level lower level commission rebate', percentage: '0.072%' },
        { description: '4 level lower level commission rebate', percentage: '0.022%' },
        { description: '5 level lower level commission rebate', percentage: '0.0066%' },
        { description: '6 level lower level commission rebate', percentage: '0.002%' },
      ] },
      { level: 'L3', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.9%' },
        { description: '2 level lower level commission rebate', percentage: '0.27%' },
        { description: '3 level lower level commission rebate', percentage: '0.081%' },
        { description: '4 level lower level commission rebate', percentage: '0.024%' },
        { description: '5 level lower level commission rebate', percentage: '0.0072%' },
        { description: '6 level lower level commission rebate', percentage: '0.0022%' },
      ] },
      { level: 'L4', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.0%' },
        { description: '2 level lower level commission rebate', percentage: '0.30%' },
        { description: '3 level lower level commission rebate', percentage: '0.090%' },
        { description: '4 level lower level commission rebate', percentage: '0.026%' },
        { description: '5 level lower level commission rebate', percentage: '0.0078%' },
        { description: '6 level lower level commission rebate', percentage: '0.0024%' },
      ] },
      { level: 'L5', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.1%' },
        { description: '2 level lower level commission rebate', percentage: '0.33%' },
        { description: '3 level lower level commission rebate', percentage: '0.099%' },
        { description: '4 level lower level commission rebate', percentage: '0.028%' },
        { description: '5 level lower level commission rebate', percentage: '0.0084%' },
        { description: '6 level lower level commission rebate', percentage: '0.0026%' },
      ] },
      { level: 'L6', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.2%' },
        { description: '2 level lower level commission rebate', percentage: '0.36%' },
        { description: '3 level lower level commission rebate', percentage: '0.108%' },
        { description: '4 level lower level commission rebate', percentage: '0.030%' },
        { description: '5 level lower level commission rebate', percentage: '0.0090%' },
        { description: '6 level lower level commission rebate', percentage: '0.0028%' },
      ] },
      { level: 'L7', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.3%' },
        { description: '2 level lower level commission rebate', percentage: '0.39%' },
        { description: '3 level lower level commission rebate', percentage: '0.117%' },
        { description: '4 level lower level commission rebate', percentage: '0.032%' },
        { description: '5 level lower level commission rebate', percentage: '0.0096%' },
        { description: '6 level lower level commission rebate', percentage: '0.0030%' },
      ] },
      { level: 'L8', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.4%' },
        { description: '2 level lower level commission rebate', percentage: '0.42%' },
        { description: '3 level lower level commission rebate', percentage: '0.126%' },
        { description: '4 level lower level commission rebate', percentage: '0.034%' },
        { description: '5 level lower level commission rebate', percentage: '0.0102%' },
        { description: '6 level lower level commission rebate', percentage: '0.0032%' },
      ] },
      { level: 'L9', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.5%' },
        { description: '2 level lower level commission rebate', percentage: '0.45%' },
        { description: '3 level lower level commission rebate', percentage: '0.135%' },
        { description: '4 level lower level commission rebate', percentage: '0.036%' },
        { description: '5 level lower level commission rebate', percentage: '0.0108%' },
        { description: '6 level lower level commission rebate', percentage: '0.0034%' },
      ] },
      { level: 'L10', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.6%' },
        { description: '2 level lower level commission rebate', percentage: '0.48%' },
        { description: '3 level lower level commission rebate', percentage: '0.144%' },
        { description: '4 level lower level commission rebate', percentage: '0.038%' },
        { description: '5 level lower level commission rebate', percentage: '0.0114%' },
        { description: '6 level lower level commission rebate', percentage: '0.0036%' },
      ] }
    ],
    Sports: [
      { level: 'L0', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.5%' },
        { description: '2 level lower level commission rebate', percentage: '0.15%' },
        { description: '3 level lower level commission rebate', percentage: '0.045%' },
        { description: '4 level lower level commission rebate', percentage: '0.013%' },
        { description: '5 level lower level commission rebate', percentage: '0.0039%' },
        { description: '6 level lower level commission rebate', percentage: '0.0012%' },
      ] },
      { level: 'L1', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.5%' },
        { description: '2 level lower level commission rebate', percentage: '0.15%' },
        { description: '3 level lower level commission rebate', percentage: '0.045%' },
        { description: '4 level lower level commission rebate', percentage: '0.013%' },
        { description: '5 level lower level commission rebate', percentage: '0.0039%' },
        { description: '6 level lower level commission rebate', percentage: '0.0012%' },
      ] },
      { level: 'L2', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.6%' },
        { description: '2 level lower level commission rebate', percentage: '0.18%' },
        { description: '3 level lower level commission rebate', percentage: '0.054%' },
        { description: '4 level lower level commission rebate', percentage: '0.016%' },
        { description: '5 level lower level commission rebate', percentage: '0.0048%' },
        { description: '6 level lower level commission rebate', percentage: '0.0014%' },
      ] },
      { level: 'L3', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.7%' },
        { description: '2 level lower level commission rebate', percentage: '0.21%' },
        { description: '3 level lower level commission rebate', percentage: '0.063%' },
        { description: '4 level lower level commission rebate', percentage: '0.018%' },
        { description: '5 level lower level commission rebate', percentage: '0.0056%' },
        { description: '6 level lower level commission rebate', percentage: '0.0017%' },
      ] },
      { level: 'L4', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.8%' },
        { description: '2 level lower level commission rebate', percentage: '0.24%' },
        { description: '3 level lower level commission rebate', percentage: '0.072%' },
        { description: '4 level lower level commission rebate', percentage: '0.022%' },
        { description: '5 level lower level commission rebate', percentage: '0.0066%' },
        { description: '6 level lower level commission rebate', percentage: '0.002%' },
      ] },
      { level: 'L5', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.9%' },
        { description: '2 level lower level commission rebate', percentage: '0.27%' },
        { description: '3 level lower level commission rebate', percentage: '0.081%' },
        { description: '4 level lower level commission rebate', percentage: '0.024%' },
        { description: '5 level lower level commission rebate', percentage: '0.0072%' },
        { description: '6 level lower level commission rebate', percentage: '0.0022%' },
      ] },
      { level: 'L6', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.0%' },
        { description: '2 level lower level commission rebate', percentage: '0.30%' },
        { description: '3 level lower level commission rebate', percentage: '0.090%' },
        { description: '4 level lower level commission rebate', percentage: '0.026%' },
        { description: '5 level lower level commission rebate', percentage: '0.0078%' },
        { description: '6 level lower level commission rebate', percentage: '0.0024%' },
      ] },
      { level: 'L7', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.1%' },
        { description: '2 level lower level commission rebate', percentage: '0.33%' },
        { description: '3 level lower level commission rebate', percentage: '0.099%' },
        { description: '4 level lower level commission rebate', percentage: '0.028%' },
        { description: '5 level lower level commission rebate', percentage: '0.0084%' },
        { description: '6 level lower level commission rebate', percentage: '0.0026%' },
      ] },
      { level: 'L8', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.2%' },
        { description: '2 level lower level commission rebate', percentage: '0.36%' },
        { description: '3 level lower level commission rebate', percentage: '0.108%' },
        { description: '4 level lower level commission rebate', percentage: '0.030%' },
        { description: '5 level lower level commission rebate', percentage: '0.0090%' },
        { description: '6 level lower level commission rebate', percentage: '0.0028%' },
      ] },
      { level: 'L9', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.3%' },
        { description: '2 level lower level commission rebate', percentage: '0.39%' },
        { description: '3 level lower level commission rebate', percentage: '0.117%' },
        { description: '4 level lower level commission rebate', percentage: '0.032%' },
        { description: '5 level lower level commission rebate', percentage: '0.0096%' },
        { description: '6 level lower level commission rebate', percentage: '0.0030%' },
      ] },
      { level: 'L10', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.4%' },
        { description: '2 level lower level commission rebate', percentage: '0.42%' },
        { description: '3 level lower level commission rebate', percentage: '0.126%' },
        { description: '4 level lower level commission rebate', percentage: '0.034%' },
        { description: '5 level lower level commission rebate', percentage: '0.0102%' },
        { description: '6 level lower level commission rebate', percentage: '0.0032%' },
      ] }
    ],
    Rummy: [
      { level: 'L0', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.6%' },
        { description: '2 level lower level commission rebate', percentage: '0.18%' },
        { description: '3 level lower level commission rebate', percentage: '0.054%' },
        { description: '4 level lower level commission rebate', percentage: '0.016%' },
        { description: '5 level lower level commission rebate', percentage: '0.0048%' },
        { description: '6 level lower level commission rebate', percentage: '0.0014%' },
      ] },
      { level: 'L1', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.6%' },
        { description: '2 level lower level commission rebate', percentage: '0.18%' },
        { description: '3 level lower level commission rebate', percentage: '0.054%' },
        { description: '4 level lower level commission rebate', percentage: '0.016%' },
        { description: '5 level lower level commission rebate', percentage: '0.0048%' },
        { description: '6 level lower level commission rebate', percentage: '0.0014%' },
      ] },
      { level: 'L2', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.7%' },
        { description: '2 level lower level commission rebate', percentage: '0.21%' },
        { description: '3 level lower level commission rebate', percentage: '0.063%' },
        { description: '4 level lower level commission rebate', percentage: '0.018%' },
        { description: '5 level lower level commission rebate', percentage: '0.0056%' },
        { description: '6 level lower level commission rebate', percentage: '0.0017%' },
      ] },
      { level: 'L3', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.8%' },
        { description: '2 level lower level commission rebate', percentage: '0.24%' },
        { description: '3 level lower level commission rebate', percentage: '0.072%' },
        { description: '4 level lower level commission rebate', percentage: '0.022%' },
        { description: '5 level lower level commission rebate', percentage: '0.0066%' },
        { description: '6 level lower level commission rebate', percentage: '0.002%' },
      ] },
      { level: 'L4', sums: [
        { description: '1 level lower level commission rebate', percentage: '0.9%' },
        { description: '2 level lower level commission rebate', percentage: '0.27%' },
        { description: '3 level lower level commission rebate', percentage: '0.081%' },
        { description: '4 level lower level commission rebate', percentage: '0.024%' },
        { description: '5 level lower level commission rebate', percentage: '0.0072%' },
        { description: '6 level lower level commission rebate', percentage: '0.0022%' },
      ] },
      { level: 'L5', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.0%' },
        { description: '2 level lower level commission rebate', percentage: '0.30%' },
        { description: '3 level lower level commission rebate', percentage: '0.090%' },
        { description: '4 level lower level commission rebate', percentage: '0.026%' },
        { description: '5 level lower level commission rebate', percentage: '0.0078%' },
        { description: '6 level lower level commission rebate', percentage: '0.0024%' },
      ] },
      { level: 'L6', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.1%' },
        { description: '2 level lower level commission rebate', percentage: '0.33%' },
        { description: '3 level lower level commission rebate', percentage: '0.099%' },
        { description: '4 level lower level commission rebate', percentage: '0.028%' },
        { description: '5 level lower level commission rebate', percentage: '0.0084%' },
        { description: '6 level lower level commission rebate', percentage: '0.0026%' },
      ] },
      { level: 'L7', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.2%' },
        { description: '2 level lower level commission rebate', percentage: '0.36%' },
        { description: '3 level lower level commission rebate', percentage: '0.108%' },
        { description: '4 level lower level commission rebate', percentage: '0.030%' },
        { description: '5 level lower level commission rebate', percentage: '0.0090%' },
        { description: '6 level lower level commission rebate', percentage: '0.0028%' },
      ] },
      { level: 'L8', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.3%' },
        { description: '2 level lower level commission rebate', percentage: '0.39%' },
        { description: '3 level lower level commission rebate', percentage: '0.117%' },
        { description: '4 level lower level commission rebate', percentage: '0.032%' },
        { description: '5 level lower level commission rebate', percentage: '0.0096%' },
        { description: '6 level lower level commission rebate', percentage: '0.0030%' },
      ] },
      { level: 'L9', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.4%' },
        { description: '2 level lower level commission rebate', percentage: '0.42%' },
        { description: '3 level lower level commission rebate', percentage: '0.126%' },
        { description: '4 level lower level commission rebate', percentage: '0.034%' },
        { description: '5 level lower level commission rebate', percentage: '0.0102%' },
        { description: '6 level lower level commission rebate', percentage: '0.0032%' },
      ] },
      { level: 'L10', sums: [
        { description: '1 level lower level commission rebate', percentage: '1.5%' },
        { description: '2 level lower level commission rebate', percentage: '0.45%' },
        { description: '3 level lower level commission rebate', percentage: '0.135%' },
        { description: '4 level lower level commission rebate', percentage: '0.036%' },
        { description: '5 level lower level commission rebate', percentage: '0.0108%' },
        { description: '6 level lower level commission rebate', percentage: '0.0034%' },
      ] }
    ],
    Slots: [
        { level: 'L0', sums: [
          { description: '1 level lower level commission rebate', percentage: '0.5%' },
          { description: '2 level lower level commission rebate', percentage: '0.15%' },
          { description: '3 level lower level commission rebate', percentage: '0.045%' },
          { description: '4 level lower level commission rebate', percentage: '0.013%' },
          { description: '5 level lower level commission rebate', percentage: '0.0039%' },
          { description: '6 level lower level commission rebate', percentage: '0.0012%' },
        ] },
        { level: 'L1', sums: [
          { description: '1 level lower level commission rebate', percentage: '0.5%' },
          { description: '2 level lower level commission rebate', percentage: '0.15%' },
          { description: '3 level lower level commission rebate', percentage: '0.045%' },
          { description: '4 level lower level commission rebate', percentage: '0.013%' },
          { description: '5 level lower level commission rebate', percentage: '0.0039%' },
          { description: '6 level lower level commission rebate', percentage: '0.0012%' },
        ] },
        { level: 'L2', sums: [
          { description: '1 level lower level commission rebate', percentage: '0.6%' },
          { description: '2 level lower level commission rebate', percentage: '0.18%' },
          { description: '3 level lower level commission rebate', percentage: '0.054%' },
          { description: '4 level lower level commission rebate', percentage: '0.016%' },
          { description: '5 level lower level commission rebate', percentage: '0.0048%' },
          { description: '6 level lower level commission rebate', percentage: '0.0014%' },
        ] },
        { level: 'L3', sums: [
          { description: '1 level lower level commission rebate', percentage: '0.7%' },
          { description: '2 level lower level commission rebate', percentage: '0.21%' },
          { description: '3 level lower level commission rebate', percentage: '0.063%' },
          { description: '4 level lower level commission rebate', percentage: '0.018%' },
          { description: '5 level lower level commission rebate', percentage: '0.0056%' },
          { description: '6 level lower level commission rebate', percentage: '0.0017%' },
        ] },
        { level: 'L4', sums: [
          { description: '1 level lower level commission rebate', percentage: '0.8%' },
          { description: '2 level lower level commission rebate', percentage: '0.24%' },
          { description: '3 level lower level commission rebate', percentage: '0.072%' },
          { description: '4 level lower level commission rebate', percentage: '0.022%' },
          { description: '5 level lower level commission rebate', percentage: '0.0066%' },
          { description: '6 level lower level commission rebate', percentage: '0.002%' },
        ] },
        { level: 'L5', sums: [
          { description: '1 level lower level commission rebate', percentage: '0.9%' },
          { description: '2 level lower level commission rebate', percentage: '0.27%' },
          { description: '3 level lower level commission rebate', percentage: '0.081%' },
          { description: '4 level lower level commission rebate', percentage: '0.024%' },
          { description: '5 level lower level commission rebate', percentage: '0.0072%' },
          { description: '6 level lower level commission rebate', percentage: '0.0022%' },
        ] },
        { level: 'L6', sums: [
          { description: '1 level lower level commission rebate', percentage: '1.0%' },
          { description: '2 level lower level commission rebate', percentage: '0.30%' },
          { description: '3 level lower level commission rebate', percentage: '0.090%' },
          { description: '4 level lower level commission rebate', percentage: '0.026%' },
          { description: '5 level lower level commission rebate', percentage: '0.0078%' },
          { description: '6 level lower level commission rebate', percentage: '0.0024%' },
        ] },
        { level: 'L7', sums: [
          { description: '1 level lower level commission rebate', percentage: '1.1%' },
          { description: '2 level lower level commission rebate', percentage: '0.33%' },
          { description: '3 level lower level commission rebate', percentage: '0.099%' },
          { description: '4 level lower level commission rebate', percentage: '0.028%' },
          { description: '5 level lower level commission rebate', percentage: '0.0084%' },
          { description: '6 level lower level commission rebate', percentage: '0.0026%' },
        ] },
        { level: 'L8', sums: [
          { description: '1 level lower level commission rebate', percentage: '1.2%' },
          { description: '2 level lower level commission rebate', percentage: '0.36%' },
          { description: '3 level lower level commission rebate', percentage: '0.108%' },
          { description: '4 level lower level commission rebate', percentage: '0.030%' },
          { description: '5 level lower level commission rebate', percentage: '0.0090%' },
          { description: '6 level lower level commission rebate', percentage: '0.0028%' },
        ] },
        { level: 'L9', sums: [
          { description: '1 level lower level commission rebate', percentage: '1.3%' },
          { description: '2 level lower level commission rebate', percentage: '0.39%' },
          { description: '3 level lower level commission rebate', percentage: '0.117%' },
          { description: '4 level lower level commission rebate', percentage: '0.032%' },
          { description: '5 level lower level commission rebate', percentage: '0.0096%' },
          { description: '6 level lower level commission rebate', percentage: '0.0030%' },
        ] },
        { level: 'L10', sums: [
          { description: '1 level lower level commission rebate', percentage: '1.4%' },
          { description: '2 level lower level commission rebate', percentage: '0.42%' },
          { description: '3 level lower level commission rebate', percentage: '0.126%' },
          { description: '4 level lower level commission rebate', percentage: '0.034%' },
          { description: '5 level lower level commission rebate', percentage: '0.0102%' },
          { description: '6 level lower level commission rebate', percentage: '0.0032%' },
        ] }
    ]
  };
  

const TabItem = ({ name, item,isActive, onClick }) => (
    <Box
    sx={{
      display: 'flex',
      flexDirection:'column',
      alignItems: 'center',
      justifyContent:"center",
      paddingTop:1,
  marginLeft:1,
      cursor: 'pointer',
      backgroundColor: isActive ? '#299FF2' : '#2B3270',
      color: isActive ? 'white' : '#A5A9BE',
      borderRadius: 1,
      transition: 'background-color 0.3s ease',
  
      '&:hover': {
        backgroundColor: '#1e88e5'
      },
      overflowY: 'auto', // Enable vertical scrolling
    '::-webkit-scrollbar': {
      width: '8px', // Scrollbar width
    },
    }}
    onClick={onClick}
  >
 <img src={isActive ? item.icon : item.icon1} alt="icon" style={{ width: "30%", height: "45%" }} />
    <Typography variant="body2">
      {name}
    </Typography>
  </Box>
);

const RebateLevelComponent = () => {
  const [activeTab, setActiveTab] = useState(tabItems[0].name);
  const navigate = useNavigate();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Mobile>
      <Box height="calc(var(--vh, 1vh) * 100)" position="relative" sx={{ backgroundColor: '#22275B' }}>
        {/* Header Section */}
    
        <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#2B3270",
                padding: "4px 8px",
                color: "white",
              }}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => navigate(-1)}
                    sx={{ color: "white", ml: -5 }}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      flexGrow: 1,
                      textAlign: "center",
                      mr: 8,
                    }}
                  >
                 Rebate Ratio
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
        {/* Tabs Section */}
          
        <Box sx={{ 
  display: 'flex', 
  overflowX: 'auto', 
  p: 1, 
  textAlign: 'center', 
  whiteSpace: 'nowrap', // Prevent wrapping of tab items
  scrollbarWidth: 'thin', // Firefox scrollbar styling
  '&::-webkit-scrollbar': {
    height: '8px' // Chrome and Safari scrollbar styling
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#333'
  }
}}>
  {tabItems.map((item) => (
    <TabItem
      key={item.name}
      name={item.name}
      item={item}
      isActive={activeTab === item.name}
      onClick={() => handleTabClick(item.name)}
      sx={{
        backgroundColor: activeTab === item.name ? '#299FF2' : 'transparent',
        color: activeTab === item.name ? 'white' : 'inherit',
        flexShrink: 0 // Prevent tab item from shrinking
      }}
    />
  ))}
</Box>

        {/* Rebate Levels List */}
        <Box sx={{ p: 2 }}>
          {rebateLevels[activeTab].map((levelData, index) => (
            <Box key={index} sx={{ mb: 2, p: 2, borderRadius: '5%', backgroundColor: '#2B3270' }}>
              {/* Title Section */}
              <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                <Typography variant="h6" sx={{ color: 'white', mt: 0.5 }}>
                  Rebate levels
                </Typography>
                <Typography variant="h6" sx={{ mx: 1, mb: -0.8, color: '#3C8FFF', fontSize: '1.5rem', fontWeight: "bold" }}>
                  {levelData.level}
                </Typography>
              </Box>

              {/* Rebate Details */}
              {levelData.sums.map((item, idx) => (
                <Box key={idx} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1 }}>
                  <svg width="15" height="15" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="white" />
                    <circle cx="50" cy="50" r="25" fill="#61A9FF" />
                  </svg>
                  <Typography variant="body2" sx={{ color: '#ACAFB3', paddingLeft: 2, textAlign: "left", width: "90%" }}>
                    {item.description}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'white', textAlign: "left" }}>
                    {item.percentage}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <br/>
        <br/>
        <br/>
      </Box>
    </Mobile>
  );
};

export default RebateLevelComponent;