import React from "react"
import Mobile from "../Components/Mobile"
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material"
import Button from "@mui/material/Button"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"
import { ChevronLeft } from "@material-ui/icons"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import DescriptionIcon from "@mui/icons-material/Description"
// import safeImg from "../../public/assets/safeBg.png"

function SafePage() {
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate(-1)
  }
  return (
    <div>
      <Mobile>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
          sx={{ backgroundColor: "#22275b" }}
        >
          <AppBar
            position="sticky"
            sx={{
              bgcolor: "#2b3270",
              boxShadow: "none",
            }}
          >
            <Toolbar>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={1} textAlign="left">
                  <IconButton color="inherit" onClick={handleBackClick}>
                    <ChevronLeft sx={{ color: "white" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={11} textAlign="center">
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "white", fontWeight: "normal" }} // White text and normal font weight
                  >
                    Safe
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Box sx={{ mx: "16px", height: "100%", mt: "5px" }}>
            <Box
              component="span"
              sx={{
                mx: "auto",
                bgcolor: "#2b3270",
                color: "red",
                fontSize: "12px",
                fontWeight: 400,
                px: "24px",
                py: "4px",
                borderRadius: "24px",
                mt: "16px",
              }}
            >
              Interest rate 0.10%
            </Box>
            <Box
              component="div"
              sx={{
                backgroundImage: "url(/assets/safeBg.png)",
                backgroundSize: "cover",
                color: "white",
                // width: "100%",
                my: "16px",
                padding: "12px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <img
                  src="/assets/safeicon.png"
                  style={{ width: "20px", objectFit: "contain" }}
                />
                <Box
                  component="span"
                  sx={{
                    border: "1px solid #ccc",
                    px: "14px",
                    py: "2px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <VerifiedUserIcon sx={{ fontSize: "16px", mr: "4px" }} />
                  <Typography sx={{ fontSize: "12px" }}>
                    {" "}
                    Financial security
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  textAlign: "left",
                  pt: "6px",
                  fontWeight: 600,
                }}
              >
                ₹0.00
              </Typography>
              <Box
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  pt: "4px",
                  pb: "20px",
                }}
              >
                24-hour estimated revenue
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {" "}
                  ₹0.00
                </Typography>
              </Box>
            </Box>
            <Box
              component="div"
              sx={{
                minHeight: "205px",
                bgcolor: "#2b3270",
                borderRadius: "10px",
              }}
            >
              <Box display="flex" sx={{ pt: "12px", color: "#ccc" }}>
                <div style={{ width: "50%", borderRight: "1px solid #374992" }}>
                  <Box
                    component="p"
                    sx={{ margin: 0, color: "red", fontSize: "18px" }}
                  >
                    $0.00
                  </Box>
                  <Box component="p" sx={{ margin: 0, fontSize: "12px" }}>
                    Generated revenue
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      border: "1px solid #ccc",
                      fontSize: "12px",
                      borderRadius: "20px",
                      px: "16px",
                      textWrap: "nowrap"
                    }}
                  >
                    My interest rate 0.1%
                  </Box>
                </div>
                <div style={{ width: "50%" }}>
                  <Box
                    component="p"
                    sx={{ margin: 0, color: "white", fontSize: "18px" }}
                  >
                    $0.00
                  </Box>
                  <Box component="p" sx={{ margin: 0, fontSize: "12px" }}>
                    Accumulated revenue
                  </Box>
                </div>
              </Box>
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                  padding: "10px 0px",
                  marginTop: "8px",
                }}
              >
                <Button
                  sx={{
                    px: "28px",
                    border: "1px solid #61a9ff",
                    color: "#61a9ff",
                    borderRadius: "8px",
                    height: "fit-content",
                    textWrap: "nowrap"
                  }}
                >
                  Transfer Out
                </Button>
                <Button
                  sx={{
                    width: "174px",
                    bgcolor: "#61a9ff",
                    color: "white",
                    borderRadius: "8px",
                    height: "fit-content",
                    textWrap: "nowrap",
                    "&:hover": {
                    bgcolor: "#61a9ff",
                  }
                  }}
                >
                  Transfer In
                </Button>
              </Box>
              <Box
                variant="p"
                sx={{
                  color: "red",
                  mt: "10px",
                  display: "flex",
                  px: "12px",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <ErrorOutlineIcon sx={{ fontSize: "14px" }} />
                <Typography sx={{ fontSize: "11px", textAlign: "left" }}>
                  {" "}
                  Funds are safe and secure, and can be transferred at any time
                </Typography>
              </Box>
              <Typography
                sx={{ mx: "auto", fontSize: "12px", color: "#ccc", mt: "10px", pb: 1 }}
              >
                Learn about safety
              </Typography>
            </Box>
            <Box sx={{ my: "16px" }}>
              <Box sx={{ display: "flex" }}>
                <DescriptionIcon sx={{ color: "#61a9ff", mr: "8px" }} />
                <Typography
                  component="p"
                  color="white"
                  sx={{ textAlign: "left", fontWeight: "600" }}
                >
                  Historical Records
                </Typography>
              </Box>
                <Button sx={{
                  width: "100%",
                  my: "18px",
                  border: "1px solid #61a9ff",
                  color: "#61a9ff",
                  borderRadius: "24px"
                }}>All History</Button>
            </Box>
          </Box>
        </Box>
      </Mobile>
    </div>
  )
}

export default SafePage
