import React, { useEffect, useState } from "react";
import Mobile from "../Components/Mobile";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Divider from "@mui/material/Divider";
import { useAuth } from "../contexts/AuthContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { MenuList, MenuItem, ListItemText } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import axios from "axios";
import { domain } from "./config";
import { ChevronRight } from "@mui/icons-material"


const ImageSubtitleGrid = ({ imageSrc, subtitle1, subtitle2, onClick }) => (
  <Grid
    container
    onClick={onClick}
    sx={{
      backgroundColor: "#2b3270",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      width: "100%",
      height: "80%",
      borderRadius: "5px",
      marginLeft: "6px",
      padding: 1,
      alignItems: "center",
      mb: 0,
      overflow: "hidden",
      cursor: "pointer",
    }}
  >
    <Grid
      item
      xs={2}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <img
        src={imageSrc}
        alt="icon"
        style={{ width: "35px", height: "35px", objectFit: "contain" }}
      />
    </Grid>
    <Grid
      item
      xs={10}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "left",
        paddingLeft: 1,
      }}
    >
      <Typography
        sx={{
          color: "#ffffff",
          fontSize: "0.95rem",
          mb: 0.5, // Space between title and subtitle
        }}
      >
        {subtitle1}
      </Typography>
      <Typography
        sx={{
          color: "#8a8a8a",
          fontSize: "0.75rem", // Adjusted for better readability
        }}
      >
        {subtitle2}
      </Typography>
    </Grid>
  </Grid>
);
const images = [
  { url: "/assets/settingicon1.png", caption: "Settings" },
  { url: "/assets/fileicon1.png", caption: "Feedback" },
  { url: "/assets/micicon1.png", caption: "Notifications" },
  {
    url: "/assets/customericon.png",
    caption: "24/7 Customer service",
  },
  { url: "/assets/bookicon1.png", caption: "Beginers's Guide" },
  { url: "/assets/allicon1.png", caption: "About Us" },
];

const AccountMain = ({ children }) => {
  const profilePhotoUrl = "assets/images/15-80f41fc6.png";
  const heading = "Profile Name";
  const lastLogin = "Last Login: 2024-02-24";
  const captionText =
    "Daily intrest rate 0.1% + VIP extra incocme safe, calculated every 1 minute ";
  const [userData, setUserData] = React.useState(null);
  const [lastAchievement, setLastAchievement] = useState(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(subtitle);
  };

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setVh);
    setVh();

    return () => window.removeEventListener("resize", setVh);
  }, []);

  const [user, setUser] = useState(null);
  console.log(user);

  useEffect(() => {
    const fetchLastAchievement = async () => {
      try {
        const response = await axios.get(`${domain}/last-achievement`, {
          withCredentials: true,
        });

        setLastAchievement(response.data.lastAchievement);
      } catch (err) {
        console.error("Error fetching last achievement:", err);
      }
    };

    fetchLastAchievement();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${domain}/user`, {
        withCredentials: true,
      });
      setUser(response.data.user);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleRefresh = () => {
    // Handle refresh logic
    fetchUserData();
  };

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const admin = sessionStorage.getItem("admin");
    setIsAdmin(admin === "true");
  }, []);

  const options = [
    {
      label: "Notifications",
      icon: "/assets/mess2.png",
      subLabel: null,
      onClick: () => navigate("/messages"),
    },
    {
      label: "Gifts",
      icon: "/assets/gifticon1.png",
      subLabel: null,
      onClick: () => navigate("/coupen-user"),
    },
    {
      label: "Game Statistics",
      icon: "/assets/staticon1.png",
      subLabel: null,
      onClick: () => navigate("/game-statistics"),
    },

    {
      label: "Language",
      icon: "/assets/webicon1.png",
      subLabel: "English",
      onClick: () => navigate("/language"),
    },
    isAdmin
      ? {
          label: "Administrative Area",
          icon: "assets/managementicon.png",
          onClick: () => navigate("/dashboard"),
        }
      : null,
  ].filter(Boolean);

  const { logout } = useAuth();

  const navigate = useNavigate();
  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleImageClick = (index) => {
    switch (index) {
      case 0: // Settings
        navigate("/settings");
        break;
      case 1: // Settings
        navigate("/feedback");
        break;
      case 2: // Notifications
        navigate("/messages");
        break;
      case 3: // 24/7 Customer service
        navigate("/customer-service");
        break;
      case 4: // 24/7 Customer service
        navigate("");
        break;
      case 5: // 24/7 Customer service
        navigate("/about-us");
        break;
      default:
        console.log(`Clicked Image ${index + 1}`);
        break;
    }
  };

  const getImageForAchievement = () => {
    if (!lastAchievement) return "../../assets/vip-zero.png";

    switch (lastAchievement) {
      case "VIP1":
        return "../../assets/vip1.png";
      case "VIP2":
        return "../../assets/vip2.png";
      case "VIP3":
        return "../../assets/vip3.png";
      case "VIP4":
        return "../../assets/vip4.png";
      case "VIP5":
        return "../../assets/vip5.png";
      case "VIP6":
        return "../../assets/vip6.png";
      case "VIP7":
        return "../../assets/vip7.png";
      case "VIP8":
        return "../../assets/vip8.png";
      case "VIP9":
        return "../../assets/vip9.png";
      case "VIP10":
        return "../../assets/vip10.png";
      default:
        return "../../assets/vip-zero.png";
    }
  };

  const subtitle = `${user ? user.uid : 0}`;

  return (
    <div>
      <Mobile>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
          sx={{
            backgroundColor: "#22275b", // Base background color
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#22275b",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#22275b",
            },
          }}
        >
          <Box flexGrow={1}>
            <Grid
              container
              sx={{
                backgroundImage:
                  "linear-gradient(90deg,#2b3270 0%, #2b3270 100%)",
                borderRadius: "0 0 20px 20px",
                padding: "20px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  height: "20px",
                }}
              ></Grid>
              <Grid item xs={3} >
                <Avatar
                  src="../../assets/1-a6662edb.png"
                  sx={{ width: 75, height: 75 }}
                />
              </Grid>
              <Grid
                item
                xs={8}
                container
                direction="column"
                justifyContent="space-between"
              >
                <Grid item align="left">
                  <Typography
                    variant="caption"
                    align="center"
                    color="white"
                    fontSize={14}
                  >
                    {user ? user.username : "Loading.."}
                  </Typography>{" "}
                  <img
                    src={getImageForAchievement()}
                    alt="Achievement"
                    width="20%"
                    height="80%"
                  />
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    borderRadius: "50px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    padding: "0 8px",
                    height: "24%",
                    backgroundColor: "rgb(221,144,56)",
                    width: "65%",
                  }}
                >
                  <Grid
                    item
                    xs={8}
                    container
                    alignItems="center"
                    direction="row"
                    display="flex"
                    mb={1}
                  >
                    <Typography
                      variant="caption"
                      fontSize={10}
                      align="left"
                      color="white"
                    >
                      {`UID`}
                    </Typography>
                    <Box
                      sx={{
                        height: "10px",
                        borderLeft: "1px solid white",
                        mx: 1,
                      }}
                    />
                    <Typography
                      variant="caption"
                      align="left"
                      color="white"
                      fontSize={10}
                    >
                      {`${user ? user.uid : 0}`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    display="flex"
                    pb={1}
                    pr={1} // Adjusted padding to ensure proper alignment
                  >
                    <IconButton onClick={handleCopy} sx={{ p: 0 }}>
                      <FileCopyIcon
                        sx={{ color: "white", width: "12px", height: "12px" }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid item align="left">
                  <Typography
                    variant="caption"
                    align="left"
                    color="white"
                  >{`Last Login: ${
                    user
                      ? new Date(user.lastLoginTime).toLocaleString()
                      : "Loading.."
                  }`}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: "100px",
                }}
              ></Grid>
            </Grid>

            <div style={{ position: "relative", marginTop: "-20%", zIndex: 1 }}>
              <Grid
                container
                sx={{
                  backgroundColor: "#374992",
                  padding: "20px",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "95%", // Decreased width
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    sx={{ color: "white", fontSize: "14px" }}
                  >
                    Total Balance
                  </Typography>
                </Grid>

                <Grid item xs={12} align="Left">
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    {`\u20B9${user ? user.walletAmount.toFixed(2) : "Loading"}`}
                    <IconButton onClick={handleRefresh}>
                      <AutorenewIcon
                        style={{ color: "white", width: "20", height: "20" }}
                      />
                    </IconButton>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ opacity: 0.3 }} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate("/wallet")}>
                        <img
                          src="assets/images/download.png"
                          alt="Wallet"
                          width="30"
                          height="30"
                        />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        sx={{ color: "white" }}
                      >
                        Wallet
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate("/recharge")}>
                        <img
                          src="assets/images/download (1).png"
                          width="30"
                          height="30"
                          alt="Deposit"
                        />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        sx={{ color: "white" }}
                      >
                        Deposit
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate("/withdraw")}>
                        <img
                          src="assets/images/download (2).png"
                          width="30"
                          height="30"
                          alt="Withdraw"
                        />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        sx={{ color: "white", textWrap: "nowrap" }}
                      >
                        Withdraw
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton onClick={() => navigate("/vip")}>
                        <img
                          src="assets/images/VipIcon-3c72b1cc.png"
                          width="30"
                          height="30"
                          alt="VIP"
                        />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        sx={{ color: "white" }}
                      >
                        VIP
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <Box
              component="div"
              onClick={() => navigate("/safepage")}
              container
              display="flex"
              p={0.8}
              borderRadius={1}
              bgcolor="#2b3270"
              mx={2}
              mt={2}
              color="white"
              sx={{
                cursor: "pointer",
              }}
            >
              <Box
                component="img"
                sx={{ objectFit: "contain" }}
                src="/assets/safeicon.png"
                alt=""
              />
              <Box>
                <Box display="flex" sx={{ justifyContent: "space-between" }}>
                  <Typography sx={{ ml: 2, fontWeight: "600" }}>
                    Safe
                  </Typography>
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        bgcolor: "#dd9038",
                        borderRadius: "200px",
                        fontSize: "14px",
                        px: 1,
                        py: 0,
                        lineHeight: "20px",
                      }}
                    >
                      ₹0.00
                    </Box>
                    <ChevronRight color="#ccc" />
                  </Box>
                </Box>
                <Typography sx={{ fontSize: "12px", textAlign: "left", pl: 1 }}>
                  Daily interest rate 0.1% + VIP extra income safe, calculated
                  every 1 minute
                </Typography>
              </Box>
            </Box>

            <Box sx={{ p: 0.8, borderRadius: 1 }}>
              <Grid
                container
                spacing={1}
                mt={1}
                sx={{
                  width: "98%",
                  height: "12rem",
                }}
              >
                <Grid item xs={6}>
                  <ImageSubtitleGrid
                    imageSrc="/assets/images/download (3).png"
                    subtitle1="Game History"
                    subtitle2="My game history"
                    onClick={() => navigate("/bet-history")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageSubtitleGrid
                    imageSrc="/assets/images/download (4).png"
                    subtitle1="Transaction"
                    subtitle2="My transaction history"
                    onClick={() => navigate("/transaction")}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: -1 }}>
                  <ImageSubtitleGrid
                    imageSrc="/assets/images/download (28).png"
                    subtitle1="Deposit"
                    subtitle2="My deposit history"
                    onClick={() => navigate("/deposit-history")}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: -1 }}>
                  <ImageSubtitleGrid
                    imageSrc="/assets/images/download (5).png"
                    subtitle1="Withdraw"
                    subtitle2="My withdraw history"
                    onClick={() => navigate("/withdraw-history")}
                  />
                </Grid>
              </Grid>
            </Box>

            <MenuList
              sx={{
                backgroundColor: "#2b3270",
                borderRadius: "8px",
                marginLeft: "auto",
                marginRight: "auto",
                width: "94%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {options.map((option, index) =>
                [
                  <MenuItem
                    key={index}
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={option.onClick}
                  >
                    <img
                      src={option.icon}
                      alt={option.label}
                      style={{ width: "24px", marginRight: "8px" }}
                    />
                    <ListItemText
                      primary={option.label}
                      sx={{ textAlign: "left", color: "white" }}
                    />
                    {option.subLabel && (
                      <ListItemText
                        secondary={option.subLabel}
                        secondaryTypographyProps={{ style: { color: "white" } }}
                      />
                    )}
                    <ArrowForwardIcon style={{ color: "white" }} />
                  </MenuItem>,
                  index < options.length - 1 && (
                    <Divider key={`divider-${index}`} />
                  ),
                ].filter(Boolean)
              )}
            </MenuList>

            <Grid
              container
              spacing={2}
              mt={2}
              sx={{
                backgroundColor: "#2b3270",
                borderRadius: "8px",
                padding: "10px",
                marginLeft: "auto",
                marginRight: "auto",
                width: "93%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {images.map((image, index) => (
                <Grid
                  item
                  xs={4}
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => handleImageClick(index)}
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={image.url}
                      alt={` ${index + 1}`}
                      style={{ width: "30%", borderRadius: "8px" }}
                    />
                    <Typography
                      variant="caption"
                      align="center"
                      sx={{ marginTop: "8px", color: "white" }}
                    >
                      {image.caption}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>

            <IconButton
              onClick={handleLogout}
              sx={{
                width: "80%",
                border: "1px solid #61a9ff",
                borderRadius: "50px",
                marginTop: "8%",
                marginBottom: "25%",
              }}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <ExitToAppIcon style={{ color: "#61a9ff" }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: "8px", color: "#61a9ff" }}
                  >
                    Log Out
                  </Typography>
                </Grid>
              </Grid>
            </IconButton>

            {/* content end */}
          </Box>

          {children}
        </Box>
      </Mobile>
    </div>
  );
};

export default AccountMain;
