import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Container,
  InputAdornment,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";
import GppGoodIcon from "@mui/icons-material/GppGood";
import usa from "../public/usa.png";
import { useState } from "react";
import Mobile from "../Components/Mobile";

const ForgotPassword = () => {
  // State to manage visibility for each password field
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });

  // Toggle visibility for the new password field
  const toggleNewPasswordVisibility = () => {
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    });
  };

  // Toggle visibility for the confirm new password field
  const toggleConfirmNewPasswordVisibility = () => {
    setShowPassword({
      ...showPassword,
      confirmNewPassword: !showPassword.confirmNewPassword,
    });
  };
  return (
    <Mobile>
      <Container
        disableGutters maxWidth="xs" sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            bgcolor: "#0F6518",
            p: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              bgcolor: "#0F6518",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ChevronLeftIcon sx={{ fontSize: 30, color: "white" }} />
            {/* <img src="path_to_in999_logo.png" alt="IN999" height="24" /> */}
            <Typography variant="h6" sx={{ color: "white" }}>
              IN999
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Select
                value="EN"
                size="small"
                disableUnderline
                sx={{
                  color: "white",
                  bgcolor: "transparent",
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                  },
                  "& .MuiSvgIcon-root": { color: "white" },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#333",
                      color: "white",
                    },
                  },
                }}
              >
                <MenuItem value="EN">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      component="img"
                      src={usa}
                      alt="USA Flag"
                      sx={{ width: 20, height: 20, borderRadius: "50%", mr: 1 }}
                    />
                    EN
                  </Box>
                </MenuItem>
              </Select>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, mt: 1, ml: 2, color: "white" }}>
            <Typography variant="h6" sx={{ flexGrow: 1, color: "white" }}>
              Forgot password
            </Typography>
            <Typography variant="body2" sx={{ my: 1 }}>
              Please change your password through your mobile phone number or
              email
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 3, mt: 2 }}>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <PhoneIcon sx={{ color: "#0F6518" }} />
            <Typography variant="body1" sx={{ color: "#0F6518" }}>
              phone reset
            </Typography>
          </Box>
          <Divider sx={{ bgcolor: "#0F6518", mb: 4 }} />
          <Box sx={{ display: "flex", mb: 1 }}>
            <PhoneIcon sx={{ color: "#0F6518", mr: 1 }} />
            <Typography variant="body2" sx={{ color: "black", fontSize: 17 }}>
              Phone number
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <TextField
              variant="outlined"
              value="+91"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                width: "25%",
                bgcolor: "white",
                borderRadius: 4,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#f5f5f5",
                  },
                },
                "& ::placeholder": {
                  color: "gray",
                },
              }}
            />
            <TextField
              variant="outlined"
              placeholder="Please enter the phone number"
              sx={{
                width: "75%",
                marginLeft: "10px",
                bgcolor: "#fff",
                borderRadius: 4,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#f5f5f5",
                  },
                },
                "& ::placeholder": {
                  color: "gray",
                },
              }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 3 }}>
            <LockIcon sx={{ mr: 1, color: "#0F6518" }} />
            <Typography variant="body2" sx={{ color: "black", fontSize: 17 }}>
              A new password
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            type={showPassword.newPassword ? "text" : "password"}
            placeholder="A new password"
            sx={{
              mb: 2,
              bgcolor: "#fff",
              borderRadius: 4,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#f5f5f5",
                },
              },
              "& ::placeholder": {
                color: "gray",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showPassword.newPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}>
            <LockIcon sx={{ mr: 1, color: "#0F6518" }} />
            <Typography variant="body2" sx={{ color: "black", fontSize: 17 }}>
              Confirm new password
            </Typography>
          </Box>

          <TextField
            fullWidth
            variant="outlined"
            type={showPassword.confirmNewPassword ? "text" : "password"}
            placeholder="Confirm new password"
            sx={{
              mb: 2,
              bgcolor: "#fff",
              borderRadius: 4,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#f5f5f5",
                },
              },
              "& ::placeholder": {
                color: "gray",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={toggleConfirmNewPasswordVisibility}
                >
                  {showPassword.confirmNewPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}>
            <GppGoodIcon sx={{ mr: 1, color: "#0F6518" }} />
            <Typography variant="body2" sx={{ color: "black", fontSize: 17 }}>
              Verification Code
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Please enter the confirmation code"
            sx={{
              mb: 2,
              bgcolor: "#fff",
              borderRadius: 4,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#f5f5f5",
                },
              },
              "& ::placeholder": {
                color: "gray",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      bgcolor: "#0F6518",
                      color: "white",
                      borderRadius: 50,
                      textTransform: "capitalize",
                      px: 4,
                      py: 1,
                    }}
                  >
                    Send
                  </Button>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column", mt: 3, gap: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    borderRadius: "50%",
                    width: 24,
                    height: 24,
                    ml: 2,
                    mr: 1,
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  I have read and agree{" "}
                  <span style={{ color: "red" }}>[Privacy Agreement]</span>
                </Typography>
              }
            />

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "60%",
                  bgcolor: "#0F6518",
                  color: "white",
                  textTransform: "capitalize",
                  borderRadius: 50,
                  "&:hover": { bgcolor: "#0F6518" },
                }}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Mobile>
  );
};

export default ForgotPassword;
