import AdminPanel from './Admin';
import SettingsMain from './SettingsMain';


const Members = ({}) => {
  return (
    <AdminPanel>
      <SettingsMain />
    </AdminPanel>
  );
};

export default Members;